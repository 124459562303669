import { create } from 'zustand';

import { storeTableService } from '../../services';
import {
  KdsClearTableResDto,
  KdsDinerPaidCashResDto,
  KdsKickDinerResDto,
  KdsStoreTableResult,
} from '../../submodules/sicpama-shared';
import { useNotificationOrderState } from '../OrderNotificationAlert/OrderNotificationAlert';

type StoreTableState = {
  storeTablesData: KdsStoreTableResult[];
  getStoreTableData: (storeId: number) => Promise<KdsStoreTableResult[]>;
  clearStoreTable: (storeId: number, tableId: number) => Promise<KdsClearTableResDto>;
  kickOutDiner: (storeId: number, tableId: number, orderId: string) => Promise<KdsKickDinerResDto>;
  dinerPaidCash: (
    storeId: number,
    tableId: number,
    orderId: string,
    paidAmount: number,
  ) => Promise<KdsDinerPaidCashResDto>;
  sendPickUpNotificationForTable: (storeId: number, tableId: number) => Promise<void>;
};

export const useStoreTableState = create<StoreTableState>((set) => ({
  storeTablesData: [] as KdsStoreTableResult[],
  getStoreTableData: async (storeId: number) => {
    try {
      const tables = await storeTableService.getStoreSortedTables(storeId);
      set({ storeTablesData: tables });
      return tables;
    } catch (error) {
      console.error(error);
      return [];
    }
  },

  clearStoreTable: async (storeId: number, tableId: number) => {
    const response = await storeTableService.clearStoreTable(storeId, tableId);
    useNotificationOrderState.getState().cleanUp();

    return response;
  },

  kickOutDiner: async (storeId: number, tableId: number, orderId: string) => {
    const response = await storeTableService.kickOutDiner(storeId, tableId, orderId);

    return response;
  },

  dinerPaidCash: async (storeId: number, tableId: number, orderId: string, paidAmount: number) => {
    const response = await storeTableService.dinerPaidCash(storeId, tableId, orderId, paidAmount);

    return response;
  },

  sendPickUpNotificationForTable: async (storeId: number, tableId: number) => {
    await storeTableService.sendPickUpNotification(storeId, tableId);
  },
}));
