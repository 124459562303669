import _ from 'lodash';
import { create } from 'zustand';

import { STORE_ATTRIBUTE_NAMES } from '../../constants';
import { KdsStoreTableOrderResult } from '../../submodules/sicpama-shared';
import { useOrderState } from '../Order/Order';
import { useStoreState } from '../Store/store';

type OrderNotificationAlertState = {
  alertsEnabled: boolean;
  getAlertsEnabled: () => Promise<boolean>;
  orderAlertData: KdsStoreTableOrderResult[];
  getInitialOrdersAlertData: () => number;
  getOrdersAlertData: () => number;
  removedOrderIds: string[];
  removeTableOrders: (tableId: number) => void;
  cleanUp: () => void;
};

const loadOrderRemovedDataFromLocalStorage = () => {
  const orderIds = localStorage.getItem('viewedOrders');
  return orderIds ? JSON.parse(orderIds) : [];
};

export const useNotificationOrderState = create<OrderNotificationAlertState>((set, get) => ({
  alertsEnabled: false,
  orderAlertData: [] as KdsStoreTableOrderResult[],
  removedOrderIds: [] as string[],

  getAlertsEnabled: async () => {
    const storeData = useStoreState.getState().storeData;
    if (_.isEmpty(storeData)) {
      return false;
    }
    const notificationAlertEnabled = storeData.attributes.find(
      (attr) => attr.name === STORE_ATTRIBUTE_NAMES.DASHBOARD_ALERT,
    );
    const enabled = notificationAlertEnabled?.value === 'true';
    set({ alertsEnabled: enabled });
    return enabled;
  },
  // This will load from local storage the orders that are already viewed before an app refresh
  getInitialOrdersAlertData: () => {
    set({ removedOrderIds: loadOrderRemovedDataFromLocalStorage() });
    return get().getOrdersAlertData();
  },
  getOrdersAlertData: () => {
    if (!get().alertsEnabled) {
      return 0;
    }
    const alertOrders = useOrderState
      .getState()
      .orderData.filter(
        (order) =>
          order.orderStatus === 'printed' &&
          order.items.length > 0 &&
          !get().removedOrderIds.includes(order.orderId),
      );
    set({ orderAlertData: alertOrders });
    return alertOrders.length;
  },

  removeTableOrders: async (tableId: number) => {
    if (!get().alertsEnabled) {
      return;
    }
    const updatedRemovedIds = get().removedOrderIds;
    const orderIdsToAdd = get()
      .orderAlertData.filter((order) => order.tableId === tableId)
      .map((order) => order.orderId);
    updatedRemovedIds.push(...orderIdsToAdd);
    set({ removedOrderIds: updatedRemovedIds });
    localStorage.setItem('viewedOrders', JSON.stringify(updatedRemovedIds));
    get().getOrdersAlertData();
  },

  cleanUp: () => {
    if (!get().alertsEnabled) {
      return;
    }
    get().getOrdersAlertData();
    const updatedRemovedIds = get().removedOrderIds.filter((orderId) =>
      get()
        .orderAlertData.map((order) => order.orderId)
        .includes(orderId),
    );
    set({ removedOrderIds: updatedRemovedIds });
    localStorage.setItem('viewedOrders', JSON.stringify(updatedRemovedIds));
  },
}));
