type LabelSize = 'md' | 'xl';
interface LabelProps {
  readonly mandatory: boolean;
  readonly label: string;
  readonly size: LabelSize;
}

function Label({ mandatory, label, size }: LabelProps) {
  const labelStyle = size === 'md' ? 'pb-2 text-gray-600' : 'pb-4 text-gray-900 font-semibold';

  return (
    <label className={`${labelStyle}`}>
      {mandatory && <span className="text-red-500 mr-1">*</span>}
      {label}:
    </label>
  );
}

export default Label;
