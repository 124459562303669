import { useEffect, useRef } from 'react';

export const useAudio = (audioSrc: string) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const audioContextRef = useRef<AudioContext | null>(null);

  useEffect(() => {
    // Create the AudioContext early on page load or after any user interaction
    const audioCtx: AudioContext = new (window.AudioContext || window?.webkitAudioContext)();
    audioContextRef.current = audioCtx;

    const aud = new Audio(audioSrc);
    audioRef.current = aud;

    // Attempt to unlock the audio context on the first user interaction
    const unlockAudio = () => {
      audioCtx.resume().then(() => {
        console.log('AudioContext unlocked');
        window.removeEventListener('touchstart', unlockAudio);
        window.removeEventListener('DOMContentLoaded', unlockAudio);
        window.removeEventListener('click', unlockAudio);
      });
    };

    window.addEventListener('touchstart', unlockAudio);
    window.addEventListener('DOMContentLoaded', unlockAudio);
    window.addEventListener('click', unlockAudio); // Listen for the first interaction to unlock

    return () => {
      window.removeEventListener('touchstart', unlockAudio);
      window.removeEventListener('DOMContentLoaded', unlockAudio);
      window.removeEventListener('click', unlockAudio);
    };
  }, [audioSrc]);

  // Provide the playAudio function to be used externally
  const playAudio = () => {
    const audio = audioRef.current;
    const audioContext = audioContextRef.current;
    if (audio && audioContext) {
      audio.play().catch((error) => {
        console.log('Playback failed: ', error);
      });
    }
  };

  return { playAudio };
};
