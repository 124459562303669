import { TFunction } from 'i18next';
import { create } from 'zustand';

import { ThermalPrinter } from '../../components/ThermalPrinterOrderList/ThermalPrinter';
import { PrintOrdersKdsData } from '../../submodules/sicpama-shared';
import { useStoreState } from '../Store/store';

type ThermalPrinterState = {
  thermalPrinter: ThermalPrinter;
  setThermalPrinter: () => void;
  getDevices: () => Promise<unknown[]>;
  printOrders: (t: unknown, data: PrintOrdersKdsData) => void;
  printQueue: Array<{ t: TFunction; data: PrintOrdersKdsData }>;
  isPrinting: boolean;
  addToPrintQueue: (printJob: { t: TFunction; data: PrintOrdersKdsData }) => void;
  processPrintQueue: () => Promise<void>;
};

export const useThermalPrinterState = create<ThermalPrinterState>((set, get) => ({
  thermalPrinter: null,
  printQueue: [],
  isPrinting: false,
  setThermalPrinter: async () => {
    const printer = new ThermalPrinter();

    const store = useStoreState.getState().storeData;

    const vendorIds = store.receiptPrinters.map((receiptPrinter) => receiptPrinter.vendorId);

    const areWiredPrinters = store.receiptPrinters.every(
      (receiptPrinter) => receiptPrinter.ipAddress === null,
    );

    if (areWiredPrinters) {
      await printer.init(vendorIds || []);
    }
    set({ thermalPrinter: printer });
  },

  getDevices: async () => {
    const devices = await get().thermalPrinter.getDevices();
    return devices;
  },

  addToPrintQueue: (printJob) => {
    set((state) => ({ printQueue: [...state.printQueue, printJob] }));
    get().processPrintQueue();
  },

  processPrintQueue: async () => {
    const { printQueue, isPrinting, thermalPrinter } = get();
    if (!isPrinting && printQueue.length > 0 && thermalPrinter) {
      set({ isPrinting: true });
      const currentJob = printQueue[0];

      // Execute the print job using the thermalPrinter's print method
      await thermalPrinter.print(currentJob.t, currentJob.data); // Adjusted to match your print method signature

      // Remove the completed job from the queue and reset isPrinting flag
      set((state) => ({
        printQueue: state.printQueue.slice(1),
        isPrinting: false,
      }));

      // Recursively process the next job in the queue
      get().processPrintQueue();
    }
  },

  printOrders: async (t: TFunction, data: PrintOrdersKdsData) => {
    get().addToPrintQueue({ t, data });
  },
}));
