import { IconProps } from './Icon.interface';

export default function TransactionIcon({ color, className }: IconProps) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="file-text">
        <path
          id="Vector"
          d="M16.3333 2.33334H6.99999C6.38115 2.33334 5.78766 2.57918 5.35007 3.01676C4.91249 3.45435 4.66666 4.04784 4.66666 4.66668V23.3333C4.66666 23.9522 4.91249 24.5457 5.35007 24.9833C5.78766 25.4208 6.38115 25.6667 6.99999 25.6667H21C21.6188 25.6667 22.2123 25.4208 22.6499 24.9833C23.0875 24.5457 23.3333 23.9522 23.3333 23.3333V9.33334L16.3333 2.33334Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M16.3333 2.33334V9.33334H23.3333"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M18.6667 15.1667H9.33334"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M18.6667 19.8333H9.33334"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_5"
          d="M11.6667 10.5H10.5H9.33334"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
