import { IconProps } from './Icon.interface';

export default function ReportIcon({ color, className }: IconProps) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="clipboard">
        <path
          id="Vector"
          d="M18.6667 4.66666H21C21.6188 4.66666 22.2123 4.91249 22.6499 5.35007C23.0875 5.78766 23.3333 6.38115 23.3333 6.99999V23.3333C23.3333 23.9522 23.0875 24.5457 22.6499 24.9832C22.2123 25.4208 21.6188 25.6667 21 25.6667H6.99999C6.38115 25.6667 5.78766 25.4208 5.35007 24.9832C4.91249 24.5457 4.66666 23.9522 4.66666 23.3333V6.99999C4.66666 6.38115 4.91249 5.78766 5.35007 5.35007C5.78766 4.91249 6.38115 4.66666 6.99999 4.66666H9.33332"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g id="Group 51435">
          <path
            id="Vector_2"
            d="M18.2622 16.801L15.0539 13.8844L12.1372 16.801L8.92886 13.8844"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_3"
            d="M15.9289 18.2594H19.4289V14.7594"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <path
          id="Vector_4"
          d="M17.5 2.33334H10.5C9.85568 2.33334 9.33334 2.85568 9.33334 3.50001V5.83334C9.33334 6.47768 9.85568 7.00001 10.5 7.00001H17.5C18.1443 7.00001 18.6667 6.47768 18.6667 5.83334V3.50001C18.6667 2.85568 18.1443 2.33334 17.5 2.33334Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
