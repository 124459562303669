import { getSalesStatusUri } from '../constants';
import { KdsStoreSalesResDto } from '../submodules/sicpama-shared';

import BaseHttpService from './base-http.service';

class StoreSalesStatusService extends BaseHttpService {
  async getSalesStatusData(
    storeId: number,
    fromDateTime: string,
    toDateTime: string,
    pageIndex: number,
    pageSize: number,
  ): Promise<KdsStoreSalesResDto> {
    const response = await this.get<KdsStoreSalesResDto>(
      getSalesStatusUri(storeId, fromDateTime, toDateTime, pageIndex, pageSize),
    );

    return response.data;
  }
}

export const storeSalesStatusService = new StoreSalesStatusService();
