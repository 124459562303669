import { ORDER_DETAIL_TAB } from 'src/types';
import { create } from 'zustand';

type OrderDetailModalState = {
  tableId: number;
  isOpen: boolean;
  activeTab: ORDER_DETAIL_TAB;
  closeDisplay: () => Promise<void>;
  openDisplay: (tableId: number, tabKey?: ORDER_DETAIL_TAB) => Promise<void>;
  setActiveTab: (tab: ORDER_DETAIL_TAB) => void;
};

export const useOrderDetailModalState = create<OrderDetailModalState>((set) => ({
  tableId: 0 as number,
  isOpen: false as boolean,
  activeTab: ORDER_DETAIL_TAB.LIST_KEY,
  closeDisplay: async () => {
    set({ isOpen: false, tableId: 0, activeTab: ORDER_DETAIL_TAB.LIST_KEY });
  },
  openDisplay: async (tableId: number, tabKey = ORDER_DETAIL_TAB.LIST_KEY) => {
    set({
      isOpen: true,
      tableId: tableId,
      activeTab: tabKey,
    });
  },
  setActiveTab: (tab: ORDER_DETAIL_TAB) => {
    set({ activeTab: tab });
  },
}));
