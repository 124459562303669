import { schema, normalize, NormalizedSchema } from 'normalizr';

import {
  CategoryDomain,
  CategoryEntities,
  MenuDomain,
  MenuEntities,
  MenuOptionChoiceDomain,
  MenuOptionChoiceEntities,
  MenuOptionDomain,
  MenuOptionEntities,
} from './menu.interface';

const MENU_OPTION_CHOICE_NAME = 'menuOptionChoices';
const menuOptionChoiceSchema = new schema.Entity(MENU_OPTION_CHOICE_NAME);

const MENU_OPTION_NAME = 'menuOptions';
const menuOptionSchema = new schema.Entity(MENU_OPTION_NAME, {
  menuOptionChoices: [menuOptionChoiceSchema],
});

const CATEGORY_NAME = 'categories';
const categorySchema = new schema.Entity(CATEGORY_NAME);

const MENU_NAME = 'menus';
const menuSchema = new schema.Entity(MENU_NAME, {
  category: categorySchema,
  menuOptions: [menuOptionSchema],
});

export interface NormalizedEntities {
  [MENU_OPTION_CHOICE_NAME]: MenuOptionChoiceEntities;
  [MENU_OPTION_NAME]: MenuOptionEntities;
  [CATEGORY_NAME]: CategoryEntities;
  [MENU_NAME]: MenuEntities;
}

interface NormalizedMenuOptionChoices {
  [MENU_OPTION_CHOICE_NAME]: MenuOptionChoiceEntities;
  [MENU_OPTION_NAME]: MenuOptionEntities;
}

export type NormalizedMenus = NormalizedSchema<NormalizedEntities, number[]>;
export type NormalizedMenuOption = NormalizedSchema<NormalizedMenuOptionChoices, null>;
export type NormalizedCategory = NormalizedSchema<{ categories: CategoryEntities }, number[]>;

export const normalizeMenus = (menus: MenuDomain[]): NormalizedMenus =>
  normalize(menus, [menuSchema]);

export const normalizeMenuOptions = (menuOptions: MenuOptionDomain[]): NormalizedMenuOption =>
  normalize(menuOptions, [menuOptionSchema]);

export const normalizedMenuOptionsChoices = (
  menuOptionChoices: MenuOptionChoiceDomain[],
): Record<number, MenuOptionChoiceDomain> =>
  menuOptionChoices.reduce((a, b) => ({ ...a, [b.id]: b }), {});

export const normalizeCategories = (categories: CategoryDomain[]): NormalizedCategory =>
  normalize(categories, [categorySchema]);
