import {
  FILTER_FROM_DATE_TIME_KEY,
  FILTER_TO_DATE_TIME_KEY,
  TRANSACTION_SEARCH_KEY,
  TRANSACTION_FILTER_TYPE,
  TRANSACTION_FILTER_PAGE_INDEX,
  TRANSACTION_FILTER_PAGE_SIZE,
} from 'src/constants';

export type Nullable<T> = T | null;

export const enum TABLE_STATUS {
  EMPTY = 'empty', // Light grey
  SITTING = 'sitting', // Orange
  SERVING = 'serving', // Green
  SERVED = 'served', // Dark grey
  CASH = 'cash', //Red
}

export const enum ORDER_DETAIL_TAB {
  LIST_KEY = 'order-list',
  INFO_KEY = 'diner-info',
}

export const enum TAB {
  TABLE = '0',
  ORDER = '1',
  DONE = '2',
}

export enum REFUND_TYPE {
  REFUND = 'REFUND',
  PATIAL_REFUND = 'PATIAL_REFUND',
  REFUNDING = 'REFUNDING',
}

export type FilterDateTimeParams = {
  [FILTER_FROM_DATE_TIME_KEY]: string | null;
  [FILTER_TO_DATE_TIME_KEY]: string | null;
};

export type TransactionFilterParams = {
  [FILTER_FROM_DATE_TIME_KEY]: string | null;
  [FILTER_TO_DATE_TIME_KEY]: string | null;
  [TRANSACTION_FILTER_TYPE]: string;
  [TRANSACTION_SEARCH_KEY]: string;
  [TRANSACTION_FILTER_PAGE_INDEX]: number;
  [TRANSACTION_FILTER_PAGE_SIZE]: number;
};
