export const getStoreTablesUri = (storeId: number) => `kds/stores/${storeId}/storeTables`;
export const clearStoreTableUri = (storeId: number, tableId: number) =>
  `kds/stores/${storeId}/storeTables/${tableId}/clear-table`;
export const kickDinerUri = (storeId: number, tableId: number, orderId: string) =>
  `kds/stores/${storeId}/storeTables/${tableId}/kick-diner/${orderId}`;
export const dinerPaidCashUri = (storeId: number, tableId: number, orderId: string) =>
  `kds/stores/${storeId}/storeTables/${tableId}/paid-cash/${orderId}`;
export const getSalesStatusUri = (
  storeId: number,
  fromDateTime: string,
  toDateTime: string,
  pageIndex: number,
  pageSize: number,
) =>
  `kds/stores/${storeId}/sales-status?fromDateTime=${fromDateTime}` +
  `&toDateTime=${toDateTime}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
export const getStoreTablesSortedUri = (storeId: number) =>
  `kds/stores/${storeId}/storeTables?sorted=true`;
