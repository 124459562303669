import { TFunction } from 'i18next';
import { render, Text, Printer, Br, Line, Cut, Row, CharacterSet } from 'react-thermal-printer';

import { PrintOrdersKdsData } from '../../submodules/sicpama-shared';
import { roundNumberTo2DecimalPlaces } from '../../utils/numberLocaleFormat';

export interface PrintOrderItem {
  menuName: string;
  quantity: number;
}

export interface PrintOrder {
  orderNumber: string;
  tableNumber: string;
  orderItems: PrintOrderItem[];
  printedAt: string;
}

export interface ReceiptPrinter {
  id: string;
  vendorId: number;
  repeatPrintingCounter: number;
  characterSet?: string;
}

export interface PrinterProps {
  order: PrintOrder;
  receiptPrinters: ReceiptPrinter[];
  customerPhoneNumber?: string;
}

export interface PrintOrderEventDto {
  printOrder: PrinterProps;
}

export class ThermalPrinter {
  private printer: USBDevice;
  private readonly navigator: Navigator;
  private connectedVendorId: number;

  constructor() {
    this.navigator = window.navigator;
  }

  async init(vendorIds: number[]) {
    const vendorIdsObj = vendorIds.map((vendorId) => ({ vendorId }));
    const devices = await this.getDevices();
    const savedDevice = vendorIds.length ? devices.find((d) => d.vendorId === vendorIds[0]) : null;

    console.info('savedDevice: ', savedDevice);

    if (savedDevice) {
      this.printer = savedDevice;
      this.connectedVendorId = savedDevice.vendorId;
    } else {
      // this one shows a user gesture dialog to pair the printer manually
      const printer = await this.navigator.usb.requestDevice({
        filters: vendorIdsObj,
      });

      if (printer) {
        this.printer = printer;
        this.connectedVendorId = printer.vendorId;
      }
    }
  }

  async getDevices() {
    const devices = await this.navigator.usb.getDevices();
    return devices;
  }

  async print(t: TFunction, data: PrintOrdersKdsData) {
    const { order, customerPhoneNumber, receiptPrinters } = data;

    const orderItems = order.orderItems;

    const connectedPrinter = receiptPrinters.find(
      (printer) => printer.vendorId === this.connectedVendorId,
    );

    const characterSet =
      (connectedPrinter?.characterSet as CharacterSet) ?? ('korea' as CharacterSet);

    const boldCommand = '\x1b\x45\x01';
    const header = (
      <>
        <Text size={{ width: 2, height: 2 }} font="B">{`${t('thermalPrinter.tableNumber')}: ${
          order.tableNumber
        }`}</Text>
        <Text size={{ width: 2, height: 2 }}>{`${t('thermalPrinter.orderNumber')}: ${
          order.orderNumber
        }`}</Text>
        {customerPhoneNumber && (
          <Text size={{ width: 2, height: 2 }}>{`${t(
            'thermalPrinter.customer',
          )}: ${customerPhoneNumber.slice(-4)}`}</Text>
        )}
        <Br />
        <Line character="=" />
        <Row
          left={`${boldCommand}${t('thermalPrinter.menu')}`}
          right={`${boldCommand}${t('thermalPrinter.quantity')}`}
        ></Row>
        <Line />
      </>
    );

    const body = (
      <>
        {orderItems.map((orderItem) => (
          <>
            <Row
              left={`${boldCommand}${orderItem.menuName}`}
              right={`${boldCommand}${roundNumberTo2DecimalPlaces(+orderItem.quantity).toString()}`}
            ></Row>
            <Br />
          </>
        ))}
      </>
    );

    const footer = (
      <>
        <Line character="=" />
        <Br />
        <Text align="right">{`${t('thermalPrinter.printedAt')}: ${order.printedAt}`}</Text>
        <Cut />
      </>
    );

    const element = (
      <Printer type="epson" width={42} characterSet={characterSet}>
        {header}
        {body}
        {footer}
      </Printer>
    );

    const renderedElement = await render(element);

    await this.printer.open();
    await this.printer.selectConfiguration(1);

    const printerInterface = this.printer.configuration.interfaces[0];

    await this.printer.claimInterface(printerInterface.interfaceNumber);

    const endpointNumber =
      printerInterface.alternate.endpoints.find((x) => x.direction === 'out')?.endpointNumber || 2;

    for (let i = 0; i < connectedPrinter.repeatPrintingCounter; i++) {
      await this.printer.transferOut(endpointNumber, renderedElement);
    }

    await this.printer.close();
  }
}
