import React, { useEffect, useRef, useState } from 'react';

interface WaypointProps {
  readonly onEnter?: () => void;
  readonly onLeave?: () => void;
}

export default function Waypoint({ onEnter = () => {}, onLeave = () => {} }: WaypointProps) {
  const elementRef = useRef(null);
  const [isIntersecting, setIsIntersecting] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting && isIntersecting !== true) {
        setIsIntersecting(true);
        return onEnter();
      }
      if (entry.isIntersecting === false && isIntersecting === true) {
        setIsIntersecting(false);
        return onLeave();
      }
    });

    observer.observe(elementRef.current);

    return () => observer.disconnect();
  }, [onEnter, onLeave, isIntersecting]);

  return <div ref={elementRef} />;
}
