import { Button, Icon, Modal, Typography } from '@sicpama/core-components';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAudioPlayerState } from 'src/States/AudioPlayer';

import AudioPlayer from './AudioPlayer';

function AudioPlayerAlertModal() {
  const canPlayAudio = useAudioPlayerState((state) => state.canPlayAudio);
  const setCanPlayAudio = useAudioPlayerState((state) => state.setCanPlayAudio);
  const setPlayAudio = useAudioPlayerState((state) => state.setPlayAudio);
  const { t } = useTranslation();
  const enableAudio = useCallback(() => {
    setCanPlayAudio(true);
    setPlayAudio(true);
    setTimeout(() => {
      AudioPlayer.instance().stopSound();
    }, 3000);
  }, [setCanPlayAudio, setPlayAudio]);

  return (
    <Modal centered opened={canPlayAudio === false} onClose={enableAudio} size="xl">
      <Typography align="center" weight="bold" className="text-xl font-bold text-center">
        {t('notificationValidation.title')}
      </Typography>
      <div className="flex flex-col items-center justify-center animate-pulse mt-4">
        <Icon name="Volume2" size={48} color="#1E90FF" />
      </div>
      <Typography size="md" align="center" className="pt-2 mb-4">
        {t('notificationValidation.content')}
      </Typography>
      <Button
        onClick={enableAudio}
        title={t('notificationValidation.confirm')}
        type="filled"
        size="lg"
        fullWidth={true}
        className="w-3/6 mx-auto bg-blue-600 hover:bg-blue-700"
      />
    </Modal>
  );
}

export default AudioPlayerAlertModal;
