/* eslint-disable @typescript-eslint/no-unused-vars */
import { QuantitySelector } from '@sicpama/core-components';
import { notifications } from '@sicpama/core-components/lib/notifications';
import { Popover, Switch } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { storeService } from '../../services';
import { useStoreState } from '../../States';

import './styles/PauseBusiness.scss';

dayjs.extend(utc);

const PauseBusiness = () => {
  const { t } = useTranslation();
  const { storeData, getStoreData } = useStoreState((state) => state);
  const isPausing = useMemo(() => {
    const { isOpen, openTime } = storeData;
    return !isOpen && dayjs(openTime).utc().format('YYYY-MM-DD HH:mm:ss') !== '2099-12-31 23:59:59';
  }, [storeData]);
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(10);

  const pause = async (state: boolean) => {
    if (isPausing || !value || isEditing) {
      notifications.show({
        withCloseButton: true,
        autoClose: 2000,
        // TODO: use translation
        message: 'Your Store is already closed!',
        color: 'red',
      });
      return;
    }
    setIsEditing(true);

    try {
      const {
        attributes,
        receiptPrinters,
        isOpen,
        paymentGatewayInfo,
        snsNotificationTemplate,
        ..._storeData
      } = storeData;
      const addedOpenTime = dayjs().add(value, 'minute');
      const openTimestamp = addedOpenTime.valueOf(); // in seconds
      const openTime = state ? addedOpenTime.utc().format('YYYY-MM-DD HH:mm:ss') : null;

      await storeService.editStore(storeData.id, {
        ..._storeData,
        openTime,
      });

      localStorage?.setItem(`${storeData.id}_openTime`, openTimestamp.toString());
      setTimeout(() => {
        localStorage?.removeItem(`${storeData.id}_openTime`);
        getStoreData(storeData.id);
      }, value * 60 * 1000);

      await getStoreData(storeData.id);
      closePopover();
    } catch (error) {
      notifications.show({
        withCloseButton: true,
        autoClose: 2000,
        message: error?.message || JSON.stringify(error),
        color: 'red',
      });
    }
    setIsEditing(false);
  };

  const closePopover = () => {
    // simulate a click on body since popover does not expose property to close itself
    const event = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    document.body.dispatchEvent(event);
  };

  const containerClassName = useMemo(() => {
    return isPausing ? 'pause-business-switch-container' : 'non-pause-business-switch-container';
  }, [isPausing]);

  return (
    <Popover
      content={
        <div>
          <div className="w-[150px]">
            <QuantitySelector
              value={value}
              onChange={(e: number) => {
                setValue(e);
              }}
              max={600}
              sx={{ maxWidth: 200 }}
            />
          </div>
          <div className="grid grid-cols-2 -mx-3 -mb-3 mt-2 h-[42px] rounded-[8px] overflow-hidden">
            <div
              className={`flex items-center justify-center text-center font-semibold bg-[#FC5C0C] text-white ${
                isEditing && 'opacity-50'
              }`}
              onClick={async () => {
                pause(true);
              }}
            >
              {t('common.start')}
            </div>
            <div
              className="flex items-center justify-center text-center font-semibold bg-[#9A9A9A] text-black"
              onClick={closePopover}
            >
              {t('common.cancel')}
            </div>
          </div>
        </div>
      }
      trigger="click"
    >
      <Switch
        checkedChildren={
          <span className="text-black text-[14px] leading-[24px]">{t('food-truck.pause')}</span>
        }
        unCheckedChildren={
          <span className="text-black text-[14px] leading-[24px]">{t('food-truck.pause')}</span>
        }
        className={`${containerClassName} !bg-white [&_[class*='ant-switch-inner-unchecked']]:!mt-[-23px] [&_[class*='ant-switch-handle']]:top-[5px] [&_[class*='ant-switch-handle']]:left-[10px] shadow h-7 w-[98px] !rounded-[10px]`}
        checked={isPausing}
      />
    </Popover>
  );
};
export default PauseBusiness;
