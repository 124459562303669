import { Checkbox } from '@sicpama/core-components';
import { notifications } from '@sicpama/core-components/lib/notifications';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { shallow } from 'zustand/shallow';

import { useOrderState, useStoreState } from '../../States';
import { OrderItemStatus } from '../../submodules/sicpama-shared';

const translationPrefix = 'table-status';
export const OrderListCheckBoxPair: React.FC<{
  orderId: string;
  orderItemId: string;
  tableId: number;
}> = ({ orderId, orderItemId, tableId }) => {
  const { t } = useTranslation();
  const storeData = useStoreState().storeData;
  const { updateOrderItem } = useOrderState();

  const orderItem = useOrderState(
    (state) =>
      state.orderData
        .find((order) => order.tableId === tableId && order.orderId === orderId)
        .items.find((item) => item.id === orderItemId),
    shallow,
  );

  const getCookedServedStatus = () => {
    if (orderItem.status === OrderItemStatus.SERVED) {
      return [OrderItemStatus.COOKED, OrderItemStatus.SERVED];
    }
    if (orderItem.status === OrderItemStatus.COOKED) {
      return [OrderItemStatus.COOKED];
    }
    return [];
  };

  const getItems = () => {
    return [
      {
        value: OrderItemStatus.COOKED,
        disabled: orderItem.status === OrderItemStatus.SERVED,
      },
      {
        value: OrderItemStatus.SERVED,
        disabled: !(
          orderItem.status === OrderItemStatus.SERVED || orderItem.status === OrderItemStatus.COOKED
        ),
      },
    ];
  };

  const updateOrderItemServingStatus = useCallback(
    async (orderItemStatus: OrderItemStatus): Promise<void> => {
      if (orderItem.status === orderItemStatus) {
        return;
      }
      await updateOrderItem(storeData.id, tableId, orderItem.id, {
        orderItemStatus,
      });
      notifications.show({
        withCloseButton: true,
        autoClose: 1000,
        message: t(`${translationPrefix}.item-${orderItemStatus}`).replace(
          '{ menuName }',
          orderItem.menuSnapshot.name,
        ),
        icon: <img src="/icons/checkBg.svg" />,
        color: 'dark',
      });
    },
    [storeData.id, orderItem, tableId, t, updateOrderItem],
  );

  const getNewStatusFromValues = (values: string[]): OrderItemStatus => {
    if (values.includes(OrderItemStatus.SERVED)) {
      return OrderItemStatus.SERVED;
    }
    if (values.includes(OrderItemStatus.COOKED)) {
      return OrderItemStatus.COOKED;
    }
    return OrderItemStatus.PAID;
  };

  const onChange = useCallback(
    async (values: string[]) => {
      const newOrderItemStatus = getNewStatusFromValues(values);
      await updateOrderItemServingStatus(newOrderItemStatus);
    },
    [updateOrderItemServingStatus],
  );
  return (
    <Checkbox
      className="order-list-checkbox-pair"
      checkboxColor="white"
      direction="horizontal"
      gutter={24}
      borderColor={storeData.color ?? 'orange'}
      iconColor={storeData.color ?? 'orange'}
      items={getItems()}
      onChange={onChange}
      size="md"
      value={getCookedServedStatus()}
    />
  );
};
