import { IconProps } from './Icon.interface';

export default function PrinterIcon({ color, className }: IconProps) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="printer">
        <path
          id="Vector"
          d="M7 10.5V2.33331H21V10.5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M7.00001 21H4.66668C4.04784 21 3.45435 20.7542 3.01676 20.3166C2.57918 19.879 2.33334 19.2855 2.33334 18.6667V12.8333C2.33334 12.2145 2.57918 11.621 3.01676 11.1834C3.45435 10.7458 4.04784 10.5 4.66668 10.5H23.3333C23.9522 10.5 24.5457 10.7458 24.9833 11.1834C25.4208 11.621 25.6667 12.2145 25.6667 12.8333V18.6667C25.6667 19.2855 25.4208 19.879 24.9833 20.3166C24.5457 20.7542 23.9522 21 23.3333 21H21"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M21 16.3333H7V25.6666H21V16.3333Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
