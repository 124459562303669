import { createRefundUri, getTransactionsUri } from '../constants';
import {
  KdsStoreRefundBodyDto,
  KdsTransactionDetailDomain,
  KdsTransactionListDto,
} from '../submodules/sicpama-shared';

import BaseHttpService from './base-http.service';

class TransactionService extends BaseHttpService {
  async getTransactionsData(
    storeId: number,
    fromDateTime: string,
    toDateTime: string,
    filterType: string,
    searchKey: string,
    pageIndex: number,
    pageSize: number,
  ): Promise<KdsTransactionListDto> {
    const response = await this.get<KdsTransactionListDto>(
      getTransactionsUri(
        storeId,
        fromDateTime,
        toDateTime,
        filterType,
        searchKey,
        pageIndex,
        pageSize,
      ),
    );

    return response.data;
  }

  async getTransactionDetailData(
    storeId: number,
    paymentId: string,
  ): Promise<KdsTransactionDetailDomain> {
    const response = await this.get<KdsTransactionDetailDomain>(
      `stores/${storeId}/transactions/${paymentId}/detail`,
    );
    return response.data;
  }

  async createRefund(storeId: number, data: KdsStoreRefundBodyDto): Promise<void> {
    await this.post(createRefundUri(storeId), data);
  }
}

export const transactionService = new TransactionService();
