import { GetTokenService } from 'src/services/storage.service';
import { useStoreState } from 'src/States/Store/store';
import { KdsSearchOrderDto } from 'src/submodules/sicpama-shared/kds/request/searchOrders.dto';

import { OrdersSelectors, useOrderState } from '../Order';
import { HttpSearchOrders } from '../orders.http.interface';

const PAGE_SIZE = 150;

const handlePagination = (loadMore: boolean, pageSize = PAGE_SIZE) => {
  if (!loadMore) {
    return 1;
  }
  const totalItem = OrdersSelectors.getSearchedOrders(useOrderState.getState());
  return Math.ceil(totalItem.length / pageSize) + 1;
};

export const searchOrders =
  (
    getToken: GetTokenService,
    searchOrders: HttpSearchOrders,
    shouldCountNumberOfSentPickUpEnabled = false,
    orderDate?: string,
    pageSize?: number,
  ) =>
  async (phoneNumber: string, loadMore: boolean) => {
    const dto: KdsSearchOrderDto = {
      page: handlePagination(loadMore, pageSize),
      pageSize: pageSize ?? PAGE_SIZE,
      phoneNumber,
    };
    const orderId = useStoreState.getState().storeData.id;
    const result = await searchOrders(getToken)(
      orderId,
      dto,
      shouldCountNumberOfSentPickUpEnabled,
      orderDate,
    );

    if (result.success) {
      if (!loadMore) {
        useOrderState.getState().clearSearchOrders();
      }
      useOrderState.getState().setSearchOrders(result.data.data);
      return result.data.data.length > 0;
    }
    return false;
  };
