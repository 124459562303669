import AudioPlayer from 'src/components/AudioPlayer/AudioPlayer';
import { create } from 'zustand';

type AudioPlayerState = {
  canPlayAudio: boolean;
  playAudio: boolean;
  setCanPlayAudio: (canPlayAudio: boolean) => void;
  setPlayAudio: (playAudio: boolean) => void;
};

export const useAudioPlayerState = create<AudioPlayerState>((set, get) => ({
  canPlayAudio: true,
  playAudio: false,
  setCanPlayAudio: (canPlayAudio: boolean) => {
    set({ canPlayAudio });
    if (canPlayAudio === true && get().playAudio === true) {
      AudioPlayer.instance().playSound();
      get().setPlayAudio(false);
    }
  },
  setPlayAudio: (playAudio: boolean) => {
    if (get().canPlayAudio === true && playAudio === true) {
      AudioPlayer.instance().playSound();
      set({ playAudio: false });
    } else {
      set({ playAudio });
    }
  },
}));
