import React from 'react';
import './Login.scss';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAudio } from 'src/hooks';
import { STORE_ATTRIBUTE_NAME } from 'src/submodules/sicpama-shared';

import { ROUTES } from '../../helpers/constants';
import { loginService } from '../../services/login.service';
import { useStoreState } from '../../States';

const LoginForm: React.FC = () => {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const { playAudio } = useAudio('https://cdn.sicpama.com/sicpama-assets/live_order.mp3');

  type LoginDto = {
    email: string;
    password: string;
  };

  const onSubmit = async (data: LoginDto) => {
    const storeId = await loginService.login(data.email, data.password);
    if (storeId > 0) {
      const storeData = await useStoreState.getState().getStoreData(storeId);
      const store = storeData.success ? storeData.data : null;
      const { attributes } = store;
      const kdsHomePathAttr = attributes?.find(
        (attr) => attr.name === STORE_ATTRIBUTE_NAME.KDS_HOME_PAGE_PATH,
      );

      const kdsHomePath = kdsHomePathAttr?.value;
      // NOTE: For SendEat, home page is not the table status page
      if (kdsHomePath && kdsHomePath !== ROUTES.TABLE_STATUS) {
        navigate(kdsHomePath);
        playAudio();
      } else {
        navigate(ROUTES.TABLE_STATUS);
      }
    }
  };

  return (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group">
        <label>Email:</label>
        <input type="email" {...register('email', { required: true })} />
      </div>
      <div className="form-group">
        <label>Password:</label>
        <input type="password" {...register('password', { required: true })} />
      </div>
      <button type="submit">Login</button>
    </form>
  );
};

export default LoginForm;
