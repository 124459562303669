export const getTransactionsUri = (
  storeId: number,
  fromDateTime: string,
  toDateTime: string,
  filterType: string,
  searchKey: string,
  pageIndex: number,
  pageSize: number,
) =>
  `stores/${storeId}/transactions?fromDateTime=${fromDateTime}&toDateTime=${toDateTime}` +
  `&filterType=${filterType}&searchKey=${searchKey}&pageIndex=${pageIndex}&pageSize=${pageSize}`;

export const createRefundUri = (storeId: number) => `stores/${storeId}/store-payments/refund`;

export const TRANSACTION_FILTER_TYPE = 'filterType';
export const TRANSACTION_SEARCH_KEY = 'searchKey';
export const TRANSACTION_FILTER_PAGE_INDEX = 'pageIndex';
export const TRANSACTION_FILTER_PAGE_SIZE = 'pageSize';
