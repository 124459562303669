import {
  bulkUpdateOrderItemsUri,
  getStoreOrdersUri,
  getCompletedOrdersUri,
  getTableOrdersUri,
  updateOrderItemUri,
} from '../constants/order.constants';
import {
  KdsBulkUpdateOrderItemsStatusDto,
  KdsStoreTableOrdersResDto,
  KdsUpdateOrderItemStatusDto,
} from '../submodules/sicpama-shared';

import BaseHttpService from './base-http.service';

class OrderService extends BaseHttpService {
  async getStoreTableOrders(storeId: number, tableId: number): Promise<KdsStoreTableOrdersResDto> {
    const body = await this.get<KdsStoreTableOrdersResDto>(getTableOrdersUri(storeId, tableId));
    return body.data;
  }

  async getStoreOrders(storeId: number): Promise<KdsStoreTableOrdersResDto> {
    if (!storeId) {
      return { orders: [] };
    }
    const body = await this.get<KdsStoreTableOrdersResDto>(getStoreOrdersUri(storeId));
    return body.data;
  }

  async updateOrderItem(
    storeId: number,
    tableId: number,
    orderItemId: string,
    updateData: KdsUpdateOrderItemStatusDto,
  ): Promise<KdsUpdateOrderItemStatusDto> {
    const body = await this.put(updateOrderItemUri(storeId, tableId, orderItemId), updateData);

    return body.data;
  }

  async bulkUpdateOrderItems(
    storeId: number,
    tableId: number,
    orderItemIds: string[],
    updateData: KdsUpdateOrderItemStatusDto,
    isFromFoodTruckStore = false,
  ): Promise<KdsBulkUpdateOrderItemsStatusDto> {
    const body = await this.put(bulkUpdateOrderItemsUri(storeId, tableId), {
      orderItemIds,
      isFromFoodTruckStore,
      ...updateData,
    });

    return body.data;
  }

  async getStoreTableCompletedOrders(storeId: number): Promise<KdsStoreTableOrdersResDto> {
    const body = await this.get<KdsStoreTableOrdersResDto>(getCompletedOrdersUri(storeId));
    return body.data;
  }
}

export const orderService = new OrderService();
