import { create } from 'zustand';

import { orderService } from '../../services';
import { KdsStoreTableOrderResult } from '../../submodules/sicpama-shared';
import {
  KdsBulkUpdateOrderItemsStatusDto,
  KdsUpdateOrderItemStatusDto,
} from '../../submodules/sicpama-shared/kds/put/orderitem.dto';

type OrderState = {
  orderData: KdsStoreTableOrderResult[];
  searchOrders: Record<number | string, KdsStoreTableOrderResult>;
  completedOrdersData: KdsStoreTableOrderResult[];
  clearSearchOrders: () => void;
  setSearchOrders: (orders: KdsStoreTableOrderResult[]) => void;
  getOrdersData: (storeId: number) => Promise<void>;
  getOrderData: (storeId: number, tableId: number) => Promise<void>;
  updateOrderItem: (
    storeId: number,
    tableId: number,
    orderItemId: string,
    updateData: KdsUpdateOrderItemStatusDto,
  ) => Promise<KdsUpdateOrderItemStatusDto>;
  bulkUpdateOrderItems: (
    storeId: number,
    tableId: number,
    orderItemIds: string[],
    updateData: KdsUpdateOrderItemStatusDto,
  ) => Promise<KdsBulkUpdateOrderItemsStatusDto>;
  getCompletedOrdersData: (storeId: number) => Promise<void>;
};

export const useOrderState = create<OrderState>((set, get) => ({
  orderData: [],
  searchOrders: {},
  completedOrdersData: [],
  clearSearchOrders: () => {
    set({ searchOrders: {} });
  },
  setSearchOrders: (orders: KdsStoreTableOrderResult[]) => {
    const normalized = orders.reduce<Record<string | number, KdsStoreTableOrderResult>>((a, b) => {
      a[b.orderId] = b;
      return a;
    }, {});
    set((state) => ({
      ...state,
      searchOrders: {
        ...state.searchOrders,
        ...normalized,
      },
    }));
  },
  getOrdersData: async (storeId: number) => {
    const response = await orderService.getStoreOrders(storeId);
    set({ orderData: response.orders });
  },

  getOrderData: async (storeId: number, tableId: number) => {
    if (!storeId || !tableId) {
      set({ orderData: [] });
      return;
    }
    const orders = (await orderService.getStoreTableOrders(storeId, tableId)).orders;

    const newOrderData = get().orderData.filter((order) => order.tableId !== tableId);
    set({ orderData: newOrderData.concat(orders) });
  },

  updateOrderItem: async (
    storeId: number,
    tableId: number,
    orderItemId: string,
    updateData: KdsUpdateOrderItemStatusDto,
  ) => {
    const result = await orderService.updateOrderItem(storeId, tableId, orderItemId, updateData);
    return result;
  },

  bulkUpdateOrderItems: async (
    storeId: number,
    tableId: number,
    orderItemIds: string[],
    updateData: KdsUpdateOrderItemStatusDto,
  ) => {
    const result = await orderService.bulkUpdateOrderItems(
      storeId,
      tableId,
      orderItemIds,
      updateData,
    );

    return result;
  },

  getCompletedOrdersData: async (storeId: number) => {
    if (!storeId) {
      set({ completedOrdersData: [] });
      return;
    }
    const result = await orderService.getStoreTableCompletedOrders(storeId);
    set({ completedOrdersData: result.orders });
  },
}));

const getSearchedOrders = (state: OrderState) => Object.keys(state.searchOrders);
const getSearchOrderById = (id: number | string) => (state: OrderState) => state.searchOrders[id];

export const OrdersSelectors = {
  getSearchedOrders,
  getSearchOrderById,
};
