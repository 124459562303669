import { GetTokenService } from 'src/services/storage.service';
import { useStoreState } from 'src/States/Store/store';

import { HttpNotifyOrder } from '../orders.http.interface';

export const notifyOrder =
  (getToken: GetTokenService, httpNotifyOrder: HttpNotifyOrder) => async (id: number | string) => {
    const storeId = useStoreState.getState().storeData.id;
    await httpNotifyOrder(getToken)(storeId, id);
    return;
  };
