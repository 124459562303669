export const ROUTES = {
  MERCHANT_SIGNUP: '/login/merchant-signup',
  LOGIN: '/login',
  TABLE_STATUS: '/app/table-status',
  SALE_STATUS: '/app/sale-status',
  MANAGEMENT: '/app/management',
  STORE_INFO: '/app/management/store-info',
  PRODUCT_MANAGEMENT: '/app/management/product-management',
  INVENTORY_MANAGEMENT: '/app/management/inventory-management',
  MENU_MANAGEMENT: '/app/management/menu-management',
  TRANSACTION_HISTORY: '/app/transaction-history',
  DATA_REPORT: '/app/data-report',
  SEARCH_ORDERS: '/app/table-status/search',
};
