import { Modal } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FadeLoader from 'react-spinners/FadeLoader';
import { useStoreState, useTransactionHistoryState } from 'src/States';
import { KdsStoreTableOrderResult, RefundReasonEnum } from 'src/submodules/sicpama-shared';
import { roundNumberTo2DecimalPlaces } from 'src/utils/numberLocaleFormat';

interface Props {
  order: KdsStoreTableOrderResult;
  onBulkComplete?: () => Promise<void>;
  loadOrders?: (loadMore?: boolean) => Promise<void>;
  isCompletedOrder?: boolean;
}

const CancelPayment = ({ order, onBulkComplete, loadOrders, isCompletedOrder }: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { createRefund } = useTransactionHistoryState();
  const [isRefunding, setIsRefunding] = useState(false);
  const { id: storeId } = useStoreState((state) => state.storeData);

  return (
    <>
      <div
        className={`w-1/4 shrink-0 text-white bg-[#393894] flex items-center justify-center text-center ${
          isCompletedOrder ? 'h-[64px] rounded-tl-[8px]' : ''
        }`}
        onClick={() => setOpen(true)}
      >
        {t('food-truck.cancel-payment')}
      </div>
      <Modal
        open={open}
        footer={null}
        centered
        onCancel={() => setOpen(false)}
        className="[&_[class*='ant-modal-content']]:!p-0"
      >
        <div className="pt-12 pb-8 px-[10px]">
          <p className="text-center font-bold leading-[24px] whitespace-pre-line">
            {t('food-truck.cancel-order-statement')}
          </p>
        </div>
        <div className="w-full grid grid-cols-2 h-14 rounded-t-[8px] overflow-hidden">
          <div
            className="text-white flex items-center justify-center h-full bg-[#393894] text-[18px]"
            onClick={async () => {
              if (isRefunding) return;
              setIsRefunding(true);
              await createRefund(storeId, {
                orders: [
                  {
                    id: order.orderId,
                    refundAmount: roundNumberTo2DecimalPlaces(+order.grandTotal),
                    paymentId: order.paymentId,
                    reason: RefundReasonEnum.CANCELED_ORDER,
                  },
                ],
                orderItems: order.items.map((item) => ({
                  id: item.id,
                  quantity: roundNumberTo2DecimalPlaces(+item.menuQuantity),
                })),
              });
              await onBulkComplete?.();
              if (isCompletedOrder) {
                await loadOrders?.();
              }
              setIsRefunding(false);
              setOpen(false);
            }}
          >
            {isRefunding ? <FadeLoader color="white" /> : t('food-truck.confirm-cancel-order')}
          </div>
          <div
            className="text-white flex items-center justify-center h-full bg-[#9a9a9a] text-[18px]"
            onClick={() => setOpen(false)}
          >
            {t('food-truck.keep-order')}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CancelPayment;
