import _ from 'lodash';
import { useAudioPlayerState } from 'src/States/AudioPlayer';

import { STORE_ATTRIBUTE_NAMES } from '../../constants';
import { useStoreState } from '../../States';

interface AudioContextStateChangeEvent extends Event {
  readonly currentTarget: AudioContext;
}

export default class AudioPlayer {
  private static instanceCache: AudioPlayer;
  private initialized = false;
  private readonly audioContext: AudioContext;
  private audioBuffer?: AudioBuffer;
  private source?: AudioBufferSourceNode;

  private constructor() {
    this.audioContext = new AudioContext();
    this.handleAudioContextStateChange = this.handleAudioContextStateChange.bind(this);
    this.audioBuffer = undefined;
    this.source = undefined;
    if (this.audioContext.state === 'suspended') {
      useAudioPlayerState.getState().setCanPlayAudio(false);
    }
    this.audioContext.addEventListener('statechange', this.handleAudioContextStateChange);
  }

  static instance() {
    if (!this.instanceCache) {
      this.instanceCache = new this();
    }
    return this.instanceCache;
  }

  public async initAudioBuffer(url: string) {
    if (this.initialized) {
      return;
    }
    const responseBuffer = await this.fetchAudioBuffer(url);
    this.audioBuffer = await this.audioContext.decodeAudioData(responseBuffer);
    this.initialized = true;
  }

  public stopSound() {
    if (this.source) {
      this.source.stop(0);
    }
  }

  public async playSound() {
    this.assertInitialized();
    this.stopSound();
    if (!this.audioBuffer) {
      throw new Error('AudioBuffer is not initialized');
    }

    this.source = this.audioContext.createBufferSource();
    this.source.connect(this.audioContext.destination);
    this.source.buffer = this.audioBuffer;
    const storeData = useStoreState.getState().storeData;
    if (!_.isEmpty(storeData)) {
      const notificationAlertEnabled = storeData.attributes.find(
        (attr) => attr.name === STORE_ATTRIBUTE_NAMES.DASHBOARD_ALERT,
      );
      if (notificationAlertEnabled && notificationAlertEnabled.value === 'true') {
        this.source.loop = true;
      }
    }
    this.source.start(0);
  }

  private handleAudioContextStateChange(ev: Event) {
    const event = ev as AudioContextStateChangeEvent;
    if (event.currentTarget.state === 'suspended') {
      useAudioPlayerState.getState().setCanPlayAudio(false);
    }
  }

  private assertInitialized() {
    if (!this.initialized) {
      throw new Error('AudioBuffer is not initialized');
    }
  }

  private async fetchAudioBuffer(url: string) {
    const response = await fetch(url, { cache: 'no-store' });
    if (response.status !== 200) {
      throw new Error(`COULD NOT FETCH AUDIO FILE FROM URL ${url}`);
    }
    return response.arrayBuffer();
  }
}
