import React from 'react';
import BounceLoader from 'react-spinners/BounceLoader';

interface GlobalLoaderProps {
  readonly open: boolean;
}

function GlobalLoader({ open }: GlobalLoaderProps) {
  if (!open) {
    return null;
  }
  return (
    <div className="fixed w-full h-screen inset-0 flex justify-center items-center bg-gray-400 opacity-30 z-50">
      <BounceLoader color="red" />
    </div>
  );
}

export default GlobalLoader;
