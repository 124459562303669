export const getAllStoreInfoUrl = (storeId: number) => `kds/stores/${storeId}/all-store-info`;

export enum STORE_ATTRIBUTE_NAMES {
  TIMEZONE = 'timezone',
  DAILY_SALES_STATUS_FILE_URL = 'dailySalesStatusFileUrl',
  NUMBER_WORKING_DAYS_TO_SEND_SETTLEMENT = 'numberWorkingDaysToSendSettlement',
  DASHBOARD_ALERT = 'dashboardAlert',
  NOTIFICATION_REQUIRED = 'notificationRequired',
  DATA_REPORT_URL = 'dataReportUrl',
  PAYMENT_TYPE = 'paymentType',
  SINGLE_CLICK = 'singleClick',
  IS_INVENTORY_ENABLED = 'isInventoryEnabled',
  COUNT_NUMBER_OF_SENT_PICK_UP_ENABLED = 'countNumberOfSentPickUpEnabled',
}

export const STORE_TIMEZONE_OFFSET_IN_MILISECONDS = {
  KR: 9 * 60 * 60 * 1000, // GTM+9
  SG: 8 * 60 * 60 * 1000, // GTM+8
};
