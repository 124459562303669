export const CURRENCY_OPTIONS = {
  KR: {
    style: 'currency',
    currency: 'KRW',
  },
  SG: {
    style: 'currency',
    currency: 'SGD',
  },
};
