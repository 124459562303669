import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { APP_LANGUAGE, NAVIGATOR_LANGUAGE_TO_APP_LANGUAGE } from '../helpers/constants';
import i18n from '../i18n';

class ContextState {
  currentAppLanguage: string;

  changeAppLanguage: (value: string) => void;
}

interface Props {
  children: ReactNode;
}

export const ConfigContext = React.createContext(new ContextState());

export const ConfigProvider: React.FC<Props> = ({ children }: Props) => {
  const initialAppLanguage = useMemo(
    () =>
      localStorage.getItem(APP_LANGUAGE) ||
      NAVIGATOR_LANGUAGE_TO_APP_LANGUAGE[window.navigator.language] ||
      'en',
    [],
  );
  const [currentAppLanguage, setCurrentAppLanguage] = useState(initialAppLanguage);

  useEffect(() => {
    i18n.changeLanguage(initialAppLanguage);
    localStorage.setItem(APP_LANGUAGE, initialAppLanguage);
  }, [initialAppLanguage]);

  const changeAppLanguage = useCallback((value) => {
    setCurrentAppLanguage(value);
    localStorage.setItem(APP_LANGUAGE, value);
    i18n.changeLanguage(value);
  }, []);

  return (
    <ConfigContext.Provider value={{ currentAppLanguage, changeAppLanguage }}>
      {children}
    </ConfigContext.Provider>
  );
};
