import _ from 'lodash';

import { OrderListTab } from '../../components/OrderList/OrderList';
import {
  KdsOrderItem,
  KdsStoreTableOrderResult,
  OrderItemStatus,
  OrderStatus,
  PaymentOption,
} from '../../submodules/sicpama-shared';

type ObjectMocKeyOrders = {
  [id: string]: KdsOrderItem[];
};

type ObjectTableOrders = {
  [id: number]: KdsStoreTableOrderResult[];
};

export type ObjectPrintedTimeTableOrders = {
  [time: number]: ObjectTableOrders;
};

export const getStrOrderIdFromOrder = (_order: KdsStoreTableOrderResult): string => {
  return `#${_order.orderId}`.substring(0, 8);
};

export const getStrOrderIdsFromOrders = (_orders: KdsStoreTableOrderResult[]): string => {
  const strOrderIds = _orders.map((order) => getStrOrderIdFromOrder(order));

  return strOrderIds.join(', ');
};

export const sortOrdersAscByUpdatedAt = (
  _orders: KdsStoreTableOrderResult[],
): KdsStoreTableOrderResult[] => {
  return _orders.sort((a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime());
};

export const sortOrdersDescByUpdatedAt = (
  _orders: KdsStoreTableOrderResult[],
): KdsStoreTableOrderResult[] => {
  return _orders.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
};

export const getOrderAmount = (_order: KdsStoreTableOrderResult): number => {
  return _order.grandTotal;
};

export const getOrdersByTableIdAndOrder = (
  _tableId: number,
  _orders: KdsStoreTableOrderResult[],
): KdsStoreTableOrderResult[] => {
  return _orders.filter((order) => order.tableId === _tableId);
};

export const getServingOrdersByOrders = (
  _orders: KdsStoreTableOrderResult[],
): KdsStoreTableOrderResult[] => {
  return _orders.filter(
    (order) =>
      order.orderStatus === OrderStatus.PRINTED &&
      order.items.length > 0 &&
      order.items.some((item) => item.status !== OrderItemStatus.SERVED),
  );
};

export const getServedOrdersByOrders = (
  _orders: KdsStoreTableOrderResult[],
): KdsStoreTableOrderResult[] => {
  return _orders.filter(
    (order) =>
      (order.orderStatus === OrderStatus.PRINTED &&
        order.items.length > 0 &&
        order.items.every((item) => item.status === OrderItemStatus.SERVED)) ||
      order.orderStatus === OrderStatus.COMPLETED,
  );
};

export const getPrintedOrdersByOrders = (
  _orders: KdsStoreTableOrderResult[],
): KdsStoreTableOrderResult[] => {
  return _orders.filter((order) => order.orderStatus === OrderStatus.PRINTED);
};

export const getOrdersByOrdersAndTableId = (
  _orders: KdsStoreTableOrderResult[],
  _tableId: number,
): KdsStoreTableOrderResult[] => {
  return _orders.filter((order) => order.tableId === _tableId);
};

export const getPrintedOrdersByOrdersAndTableId = (
  _orders: KdsStoreTableOrderResult[],
  _tableId: number,
) => {
  const orders = getOrdersByOrdersAndTableId(_orders, _tableId);

  return getPrintedOrdersByOrders(orders);
};

export const getNumItemsInOrders = (_orders: KdsStoreTableOrderResult[]): number => {
  let result = 0;

  _orders.forEach((order) => {
    if (_.isEmpty(order.items)) {
      return;
    }

    result += order.items.length;
  });

  return result;
};

export const getNumServedItemsInOrders = (_orders: KdsStoreTableOrderResult[]): number => {
  let result = 0;

  _orders.forEach((order) => {
    if (_.isEmpty(order.items)) {
      return;
    }

    const servedItems = order.items.filter((item) => item.status === OrderItemStatus.SERVED);
    result += servedItems.length;
  });

  return result;
};

export const getOrderPrintedTime = (_order: KdsStoreTableOrderResult): Date | null => {
  // Old data don't have no value printedAt value
  if (_order.orderStatus === OrderStatus.PRINTED || _order.orderStatus === OrderStatus.COMPLETED) {
    return _order.printedAt || _order.updatedAt;
  }

  return null;
};

export const getCombineOrderItems = (_orders: KdsStoreTableOrderResult[]): KdsOrderItem[] => {
  return getCombineOrderItemsByMocKey(_orders);
};

export const getCombineOrderItemsByMocKey = (
  _orders: KdsStoreTableOrderResult[],
): KdsOrderItem[] => {
  const result = [];
  const mocKeyOrders: ObjectMocKeyOrders = {};

  _orders.forEach((order) => {
    if (!order.items) {
      return;
    }

    order.items.forEach((orderItem) => {
      if (!mocKeyOrders[orderItem.mocKey]) {
        mocKeyOrders[orderItem.mocKey] = [];
      }

      mocKeyOrders[orderItem.mocKey].push(orderItem);
    });
  });

  for (const key in mocKeyOrders) {
    const items = mocKeyOrders[key];
    const item = items[0];

    let totalQuantity = 0;
    let totalPrice = 0;
    items.forEach((item) => {
      totalQuantity += Number(item.menuQuantity);
      totalPrice += Number(item.totalPrice);
    });

    result.push({ ...item, menuQuantity: totalQuantity, totalPrice });
  }

  return result;
};

export const getAddDinerPayCashButton = (
  _orders: KdsStoreTableOrderResult[],
  _order: KdsStoreTableOrderResult,
): boolean => {
  return [OrderStatus.WAITING, OrderStatus.PAYING].includes(_order.orderStatus);
};

// TODO: move this logic into the order-service and store it in order and also use in web app
export const getAmountDueOrPaid = (
  _order: KdsStoreTableOrderResult,
  _orders: KdsStoreTableOrderResult[],
): number => {
  if (_order.paymentGroupId === null) {
    return _order.grandTotal;
  }
  if (_order?.paymentOption === PaymentOption.ONE_OVER_N) {
    const filteredOrders = _orders.filter((x) => x?.paymentGroupId === _order?.paymentGroupId);
    if (filteredOrders.length === 0) {
      return _order.grandTotal;
    }
    // eslint-disable-next-line no-array-reduce/no-reduce
    const grandTotalSum = filteredOrders.reduce((acc, order) => {
      const grandTotal = +order?.grandTotal;
      return +acc + grandTotal;
    }, 0);

    return Math.round(grandTotalSum / filteredOrders.length);
  }
  return _order?.grandTotal;
};

export const getObjPrintedTimeOrdersByOrders = (
  _orders: KdsStoreTableOrderResult[],
  orderListType: OrderListTab = OrderListTab.SERVING,
): ObjectPrintedTimeTableOrders => {
  const objResult = {};

  if (_.isEmpty(_orders)) {
    return objResult;
  }

  const tempObjData = {};
  _orders.forEach((order) => {
    const dPrintedTime = getOrderPrintedTime(order);
    if (!dPrintedTime) {
      return;
    }

    const printedTime = new Date(dPrintedTime).getTime();
    if (!tempObjData[printedTime]) {
      tempObjData[printedTime] = {};
    }

    if (!tempObjData[printedTime][order.tableId]) {
      tempObjData[printedTime][order.tableId] = [];
    }

    tempObjData[printedTime][order.tableId].push(order);
  });

  // For done sort desc, for serving sort asc
  const sortedKeys =
    orderListType === OrderListTab.DONE
      ? Object.keys(tempObjData).sort((a, b) => Number(b) - Number(a))
      : Object.keys(tempObjData).sort((a, b) => Number(a) - Number(b));
  sortedKeys.forEach((key) => {
    objResult[key] = tempObjData[key];
  });

  return objResult;
};
