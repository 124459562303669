import React from 'react';
import { KdsBaseMenuOptionResDto, KdsBaseMenuSnapshotResDto } from 'src/submodules/sicpama-shared';

const flattenMenuOptions = (menuOptions: KdsBaseMenuOptionResDto[]): React.ReactNode[] => {
  return menuOptions.flatMap((option: KdsBaseMenuOptionResDto, index: number) => {
    const choicesElements = option.choices.map((choice) =>
      React.createElement('span', { key: choice.id, className: 'text-[#FC5C0C]' }, choice.name),
    );

    const choicesString =
      choicesElements.length > 0
        ? ['-', ...choicesElements.flatMap((el, i) => (i > 0 ? [', ', el] : [el]))]
        : [];

    return [
      ...(index > 0 ? ['; '] : []),
      React.createElement('span', { key: option.id, className: 'text-[#004FA4]' }, option.name),
      ...choicesString,
    ];
  });
};

export const MenuNameComponent: React.FC<{ menuSnapshot: KdsBaseMenuSnapshotResDto }> = ({
  menuSnapshot,
}) => {
  const flattenedOptions = flattenMenuOptions(menuSnapshot.menuOptions);
  const hasOptions = menuSnapshot.menuOptions.length > 0;

  return React.createElement(
    'div',
    { className: '' },
    React.createElement('span', { className: 'text-[21px]' }, `- ${menuSnapshot.name}`),
    hasOptions && React.createElement('span', { className: 'text-[21px]' }, '→'),
    React.createElement('span', { className: 'text-[21px]' }, ...flattenedOptions),
  );
};
