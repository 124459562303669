import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import LoginForm from './components/Login/Login';
import { useStoreState } from './States';

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const getStoreData = useStoreState((state) => state.getStoreData);
  const getFingerPrint = useStoreState((state) => state.getFingerPrint);
  const storeId = useStoreState((state) => state.storeData?.id);
  const [loading, setLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const initialize = useCallback(async () => {
    setLoading(true);
    if (Number.isNaN(parseInt(localStorage.getItem('STORE_ID')))) {
      setLoading(false);
      return;
    }
    const id = storeId !== undefined ? storeId : parseInt(localStorage.getItem('STORE_ID'));
    const result = await getStoreData(id);
    if (!result.success) {
      localStorage.removeItem('ACCESS_TOKEN');
      localStorage.removeItem('STORE_ID');
    } else {
      setIsAuthenticated(true);
      const currentTimestamp = new Date().getTime();
      const openTimestamp = parseInt(localStorage.getItem(`${id}_openTime`));
      if (openTimestamp && openTimestamp > currentTimestamp) {
        setTimeout(() => {
          localStorage.removeItem(`${id}_openTime`);
          getStoreData(id);
        }, openTimestamp - currentTimestamp);
      } else {
        localStorage.removeItem(`${id}_openTime`);
      }
    }
    await getFingerPrint();
    setLoading(false);
  }, [getStoreData, storeId, getFingerPrint]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <LoginForm />;
  }

  return <>{children}</>;
};
