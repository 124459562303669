import { create } from 'zustand';

import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../constants';
import { getStoreStartEndDtYmdHisByStoreData } from '../../helpers/functions';
import { storeSalesStatusService } from '../../services';
import { KdsStoreResDto, KdsStoreSalesResDto } from '../../submodules/sicpama-shared';

type StoreSalesState = {
  storeSalesData: KdsStoreSalesResDto;
  hasMoreMenuSalesData: boolean;
  getStoreSalesData: (
    storeData: KdsStoreResDto,
    fromDateTime: string,
    toDateTime: string,
    pageIndex: number,
    pageSize?: number,
  ) => Promise<KdsStoreSalesResDto>;
};

export const useStoreSalesState = create<StoreSalesState>((set, get) => ({
  storeSalesData: {} as KdsStoreSalesResDto,
  hasMoreMenuSalesData: false as boolean,
  getStoreSalesData: async (
    storeData: KdsStoreResDto,
    fromDateTime?: string,
    toDateTime?: string,
    pageIndex?: number,
    pageSize?: number,
  ) => {
    const { startDateTime, endDateTime } = getStoreStartEndDtYmdHisByStoreData(storeData);
    const numMenuSalesPerPage = pageSize || DEFAULT_PAGE_SIZE;

    let storeSalesData = await storeSalesStatusService.getSalesStatusData(
      storeData.id,
      fromDateTime || startDateTime,
      toDateTime || endDateTime,
      pageIndex || DEFAULT_PAGE_INDEX,
      numMenuSalesPerPage,
    );

    if (pageIndex > 1) {
      const previousMenuSales = get().storeSalesData.menuSales;
      const menuSales = previousMenuSales.concat(storeSalesData.menuSales);

      storeSalesData = {
        ...get().storeSalesData,
        menuSales,
      };
    }

    set({
      storeSalesData,
      hasMoreMenuSalesData: pageIndex < storeSalesData.totalPageMenuSales,
    });

    return storeSalesData;
  },
}));
