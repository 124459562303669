import {
  getStoreTablesUri,
  clearStoreTableUri,
  kickDinerUri,
  dinerPaidCashUri,
  getStoreTablesSortedUri,
} from '../constants';
import {
  KdsClearTableResDto,
  KdsDinerPaidCashResDto,
  KdsKickDinerResDto,
  KdsStoreTableResult,
  KdsStoreTablesResDto,
} from '../submodules/sicpama-shared';

import BaseHttpService from './base-http.service';

const postSendPickUpNotificationUri = (storeId: number, tableId: number) =>
  `kds/stores/${storeId}/storeTables/${tableId}/send-pick-up-notification`;
class StoreTableService extends BaseHttpService {
  async getStoreTables(storeId: number): Promise<KdsStoreTableResult[]> {
    if (!storeId) {
      return [];
    }
    const body = await this.get<KdsStoreTablesResDto>(getStoreTablesUri(storeId));
    return body.data.tables;
  }

  async getStoreSortedTables(storeId: number): Promise<KdsStoreTableResult[]> {
    if (!storeId) {
      return [];
    }
    const body = await this.get<KdsStoreTablesResDto>(getStoreTablesSortedUri(storeId));
    return body.data.tables;
  }

  async clearStoreTable(storeId: number, tableId: number): Promise<KdsClearTableResDto> {
    const body = await this.put(clearStoreTableUri(storeId, tableId));
    return body.data;
  }

  async kickOutDiner(
    storeId: number,
    tableId: number,
    orderId: string,
  ): Promise<KdsKickDinerResDto> {
    const body = await this.put(kickDinerUri(storeId, tableId, orderId));
    return body.data;
  }

  async dinerPaidCash(
    storeId: number,
    tableId: number,
    orderId: string,
    paidAmount: number,
  ): Promise<KdsDinerPaidCashResDto> {
    const response = await this.put(dinerPaidCashUri(storeId, tableId, orderId), { paidAmount });
    if (response.status !== 200) {
      throw new Error(response.statusText);
    }
    return response.data;
  }

  async sendPickUpNotification(storeId: number, tableId: number): Promise<void> {
    await this.post(postSendPickUpNotificationUri(storeId, tableId));
  }

  async sendOrderReceipt(storeId: number, orderId: string): Promise<void> {
    await this.post(`kds/stores/${storeId}/orders/${orderId}/send-receipt`);
  }
}

export const storeTableService = new StoreTableService();
