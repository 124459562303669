import { useChannel } from '@ably-labs/react-hooks';
import React from 'react';

const EventListener: React.FC<{
  channelName: string;
  msgKey: string;
  callBackFun: (_message: any) => void;
}> = ({ channelName, msgKey, callBackFun }) => {
  useChannel(channelName, msgKey, async (_message) => callBackFun(_message));
  return <></>;
};

export default EventListener;
