import i18n from '../../../src/i18n';
import { KdsTransactionListItemDomain } from '../../submodules/sicpama-shared';
import { REFUND_TYPE } from '../../types';

export const getTransactionPaymentLabel = (transaction: KdsTransactionListItemDomain): string => {
  const langPrefix = 'transaction_history';

  if (transaction.totalRefunded <= 0) {
    return i18n.t(`${langPrefix}.paid`);
  }
  const labels = {
    [REFUND_TYPE.REFUND]: i18n.t(`${langPrefix}.refunded`),
    [REFUND_TYPE.PATIAL_REFUND]: i18n.t(`${langPrefix}.partial_refund`),
  };
  return transaction.totalPaid ? labels[REFUND_TYPE.REFUND] : labels[REFUND_TYPE.PATIAL_REFUND];
};

export const formatPaymentTime = (paymentAt: string) => {
  const hour = Number(paymentAt.match(/(\d{2}):(\d{2})/)[1]);
  const minute = Number(paymentAt.match(/(\d{2}):(\d{2})/)[2]);
  let result = '';

  let strMinute = `${minute}`;
  if (minute < 10) {
    strMinute = `0${strMinute}`;
  }

  if (hour < 12) {
    result = `${hour}:${strMinute} AM`;
  } else {
    const hour12Format = (hour - 12).toString().padStart(2, '0');
    result = `${hour12Format}:${strMinute} PM`;
  }

  return result;
};
