import { useCallback, useMemo, useState } from 'react';

import Label from './Label';

interface SelectItem<T> {
  readonly value: T;
  readonly label: string;
}

interface SelectProps<T> {
  readonly items: SelectItem<T>[];
  readonly onSelect: (value: T) => void;
  readonly defaultValue?: SelectItem<T>;
  readonly label?: string;
  readonly value: T;
  readonly className?: string;
  readonly labelClassName?: string;
}

interface SelectItemProps<T> {
  readonly item: SelectItem<T>;
  readonly selected: boolean;
  readonly onClick: (value: SelectItem<T>) => void;
}

function SelectListItem<T>({ onClick, item, selected }: SelectItemProps<T>) {
  const handleClick = useCallback(() => onClick(item), [onClick, item]);
  return (
    <li
      className={`text-gray-900 relative cursor-default select-none py-4 pl-3 pr-9 border-b border-gray-300 ${
        selected ? 'bg-indigo-300' : ''
      }`}
      id="listbox-option-0"
      role="option"
      onClick={handleClick}
    >
      <div className="flex items-center">
        <span className="font-normal ml-3 block truncate">{item.label}</span>
      </div>
      {selected && (
        <span className="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4">
          <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" />
          </svg>
        </span>
      )}
    </li>
  );
}

function Select<T>({ items, onSelect, className, label, labelClassName, value }: SelectProps<T>) {
  const selectedItem = useMemo(() => items.find((item) => item.value === value), [value, items]);
  const [showSelection, setShowSelection] = useState(false);
  const handleClick = useCallback(() => setShowSelection((e) => !e), []);

  const handleSelect = useCallback(
    (v: SelectItem<T>) => {
      setShowSelection(false);
      onSelect(v.value);
    },
    [onSelect],
  );

  const isItemSelected = (value: T) => value === selectedItem?.value;

  return (
    <div className={`relative w-full flex flex-grow ${className}`}>
      {label && <Label label={label} size="md" mandatory={false} />}
      <div className="relative w-full flex flex-grow">
        <button
          onClick={handleClick}
          type="button"
          className="select-button relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6"
        >
          <span className={`flex items-center ${labelClassName}`}>
            <span className="ml-3 block truncate">{selectedItem?.label}</span>
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
            <svg
              className="h-5 w-5 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" />
            </svg>
          </span>
        </button>
      </div>
      {showSelection && (
        <ul className="top-10 absolute z-50 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {items.map((item) => (
            <SelectListItem
              key={`${item.value}`}
              onClick={handleSelect}
              selected={isItemSelected(item.value)}
              item={item}
            />
          ))}
        </ul>
      )}
    </div>
  );
}

export default Select;
