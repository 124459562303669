import { IconProps } from './Icon.interface';

export default function DatabaseIcon({ color, className }: IconProps) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="database">
        <path
          id="Vector"
          d="M14 9.33331C19.799 9.33331 24.5 7.76631 24.5 5.83331C24.5 3.90032 19.799 2.33331 14 2.33331C8.20101 2.33331 3.5 3.90032 3.5 5.83331C3.5 7.76631 8.20101 9.33331 14 9.33331Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M24.5 14C24.5 15.9367 19.8333 17.5 14 17.5C8.16667 17.5 3.5 15.9367 3.5 14"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M3.5 5.83331V22.1666C3.5 24.1033 8.16667 25.6666 14 25.6666C19.8333 25.6666 24.5 24.1033 24.5 22.1666V5.83331"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
