import axios from 'axios';
import { getDefaultAxiosConfig, handleHttpResult } from 'src/services/http';
import { GetTokenService } from 'src/services/storage.service';
import { KdsSearchOrderDto } from 'src/submodules/sicpama-shared/kds/request/searchOrders.dto';

import { HttpNotifyOrder, HttpSearchOrders } from '../orders.http.interface';

export const httpSearchOrders: HttpSearchOrders =
  (getToken: GetTokenService) =>
  async (
    storeId: number,
    dto: KdsSearchOrderDto,
    countNumberOfSentPickUpEnabled = false,
    orderDate?: string,
  ) => {
    const result = await axios.get(`v1/stores/${storeId}/orders`, {
      ...getDefaultAxiosConfig(getToken),
      params: {
        ...dto,
        countNumberOfSentPickUpEnabled: countNumberOfSentPickUpEnabled ? 'true' : undefined,
        orderDate,
      },
    });
    return handleHttpResult(result);
  };

export const httpNotifyOrder: HttpNotifyOrder =
  (getToken: GetTokenService) => async (storeId: number, orderId: number | string) => {
    const result = await axios.post(
      `v1/kds/stores/${storeId}/orders/${orderId}/send-pick-up-notification`,
      {},
      getDefaultAxiosConfig(getToken),
    );
    return handleHttpResult(result);
  };
