import { create } from 'zustand';

type ConfirmModalState = {
  title: string;
  message: string;
  isOpen: boolean;
  callBackFun: () => void;
  openConfirmModal: (_title: string, _message: string, _callBackFunc: () => void) => Promise<void>;
  closeConfirmModal: () => Promise<void>;
  setConfirmModalData: (
    __title: string,
    _message: string,
    _callBackFunc: () => void,
  ) => Promise<void>;
  toggleDisplay: () => Promise<void>;
};

export const useConfirmModalState = create<ConfirmModalState>((set, get) => ({
  title: '' as string,
  message: '' as string,
  isOpen: false as boolean,
  callBackFun: () => {},

  openConfirmModal: async (_title: string, _message: string, _callBackFunc: () => void) => {
    get().setConfirmModalData(_title, _message, _callBackFunc);
    get().toggleDisplay();
  },

  closeConfirmModal: async () => {
    get().toggleDisplay();
  },

  setConfirmModalData: async (_title: string, _message: string, _callBackFunc: () => void) => {
    set({ title: _title });
    set({ message: _message });
    set({ callBackFun: _callBackFunc });
  },

  toggleDisplay: async () => {
    set((state) => ({ isOpen: !state.isOpen }));
  },
}));
