import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { v4 as uuid } from 'uuid';

interface ModalProps {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly size: 'sm' | 'md' | 'xl';
}

interface ModalContextProps {
  registerModal: (id: string) => string;
  unregisterModal: (id: string) => void;
  getIndex: (id: string) => number;
}
const ModalContext = createContext<ModalContextProps>(null);

const useModal = () => useContext(ModalContext);

export function ModalProvider({ children }: PropsWithChildren) {
  const [modals, setModals] = useState<string[]>([]);

  const registerModal = useCallback((id: string) => {
    setModals((e) => e.concat(id));
    return id;
  }, []);
  const unregisterModal = useCallback((id: string) => {
    setModals((m) => m.filter((i) => i !== id));
  }, []);

  const getIndex = useCallback(
    (id?: string) => {
      if (id === undefined) {
        return 1000;
      }
      const idx = modals.findIndex((m) => m === id);
      return 1000 + idx;
    },
    [modals],
  );
  return (
    <ModalContext.Provider value={{ registerModal, unregisterModal, getIndex }}>
      {children}
    </ModalContext.Provider>
  );
}

function Modal({ open, onClose, size, children }: PropsWithChildren<ModalProps>) {
  const { registerModal, unregisterModal, getIndex } = useModal();
  const displayStyle = open ? '' : 'hidden';
  const [id] = useState(uuid());
  const zIndex = getIndex(id);
  const sizeStyle = () => {
    switch (size) {
      case 'sm':
        return 'w-1/4';
      case 'md':
        return 'w-2/4';
      case 'xl':
        return 'w-3/4';
      default:
        return 'w-2/4';
    }
  };

  useEffect(() => {
    if (open === true) {
      registerModal(id);
    }
    return () => {
      if (open === false) {
        unregisterModal(id);
      }
    };
  }, [id, registerModal, unregisterModal, open]);

  return createPortal(
    <>
      <div
        style={{
          zIndex: zIndex,
        }}
        className={`min-w-screen min-h-screen w-screen h-screen bg-gray-800 opacity-30 ${displayStyle} fixed top-0 left-0`}
        onClick={onClose}
      ></div>
      <div
        style={{
          zIndex: zIndex + 1,
        }}
        className={`${displayStyle} ${sizeStyle()} fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col rounded-xl p-4 bg-white shadow-xl`}
      >
        {children}
      </div>
    </>,
    document.body,
  );
}

export default Modal;
