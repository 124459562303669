import {
  KdsLoginResponseDto,
  KdsStoreLoginBodyDto,
} from '../submodules/sicpama-shared/kds/get/login.dto';

import BaseHttpService from './base-http.service';

const LOGIN_API_URL = 'login';

class LoginService extends BaseHttpService {
  getStaffToken(): string | null {
    return localStorage.getItem('ACCESS_TOKEN');
  }

  async login(email: string, password: string): Promise<number> {
    const response = await this.postWithoutToken<KdsLoginResponseDto, KdsStoreLoginBodyDto>(
      LOGIN_API_URL,
      {
        email,
        password,
      },
    );
    localStorage.setItem('ACCESS_TOKEN', response?.data?.token ?? 'NO_TOKEN');
    localStorage.setItem('STORE_ID', response?.data?.storeId.toString() ?? 'UNDEFINED');
    return response?.data?.storeId ?? 0;
  }
}
export const loginService = new LoginService();
