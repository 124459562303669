import _ from 'lodash';

import {
  COUNTRY_CODES,
  STORE_TIMEZONE_OFFSET_IN_MILISECONDS,
  STORE_ATTRIBUTE_NAMES,
} from '../../constants';
import { KdsStoreResDto } from '../../submodules/sicpama-shared';
import { StoreStartEndDateTime } from '../../types';

import { parseUnixTimestampToStrDtYmdHis } from './Common';

export const getTzOffsetInMillisecondsByStoreData = (storeData: KdsStoreResDto): number => {
  if (_.isEmpty(storeData)) {
    return 0;
  }

  const result =
    STORE_TIMEZONE_OFFSET_IN_MILISECONDS[storeData.countryCode] ||
    STORE_TIMEZONE_OFFSET_IN_MILISECONDS[COUNTRY_CODES.KR];

  if (_.isEmpty(storeData) || !storeData.attributes || storeData.attributes.length === 0) {
    return result;
  }

  const timezoneAttr = storeData.attributes.find(
    (attr) => attr.name === STORE_ATTRIBUTE_NAMES.TIMEZONE,
  );

  if (!timezoneAttr) {
    return result;
  }

  const timeZoneVal = timezoneAttr.value;
  const timeZoneParts = timeZoneVal.split(':');
  if (timeZoneParts.length < 2) {
    return result;
  }

  const hoursOffset = Number(timeZoneParts[0]) || 0;
  const minsOffset = Number(timeZoneParts[1]) || 0;

  return (hoursOffset * 60 + minsOffset) * 60 * 1000;
};

export const getStoreStartEndDtYmdHisByStoreData = (
  storeData: KdsStoreResDto,
): StoreStartEndDateTime => {
  const tzOffSetNum = getTzOffsetInMillisecondsByStoreData(storeData);
  const currentStoreTimestamp = Date.now() + tzOffSetNum;

  const currentStoreDtStr = parseUnixTimestampToStrDtYmdHis(currentStoreTimestamp);
  const dtParts = currentStoreDtStr.split(' ');
  const strYmd = dtParts[0];

  return {
    startDateTime: `${strYmd} 00:00:00`,
    endDateTime: `${strYmd} 23:59:59`,
  };
};

export const getStoreSalesByDateFileUrlByStoreData = (storeData: KdsStoreResDto): string => {
  const result = '';

  if (_.isEmpty(storeData)) {
    return result;
  }

  if (_.isEmpty(storeData) || !storeData.attributes || storeData.attributes.length === 0) {
    return result;
  }

  const timezoneAttr = storeData.attributes.find(
    (attr) => attr.name === STORE_ATTRIBUTE_NAMES.DAILY_SALES_STATUS_FILE_URL,
  );

  if (!timezoneAttr) {
    return result;
  }

  return timezoneAttr.value;
};

export const getStrNumWkDays2SendSettlementByStoreData = (storeData: KdsStoreResDto): string => {
  const result = '';

  if (_.isEmpty(storeData)) {
    return result;
  }

  if (_.isEmpty(storeData) || !storeData.attributes || storeData.attributes.length === 0) {
    return result;
  }

  const numWkDaysAttr = storeData.attributes.find(
    (attr) => attr.name === STORE_ATTRIBUTE_NAMES.NUMBER_WORKING_DAYS_TO_SEND_SETTLEMENT,
  );

  if (!numWkDaysAttr) {
    return result;
  }

  return numWkDaysAttr.value;
};

export const parseStrDateTime2StoreDateTimeYmdHis = (
  inputDate: string,
  storeData: KdsStoreResDto,
): string => {
  const tzOffSetNum = getTzOffsetInMillisecondsByStoreData(storeData);
  const unixTimeStamp = new Date(inputDate).getTime() + tzOffSetNum;

  return parseUnixTimestampToStrDtYmdHis(unixTimeStamp);
};

export const parseStrDateTime2StoreDateTimeYmd = (
  inputDate: string,
  storeData: KdsStoreResDto,
): string => {
  const strDtYmdHis = parseStrDateTime2StoreDateTimeYmdHis(inputDate, storeData);
  const strDtYmdHisParts = strDtYmdHis.split(' ');

  if (strDtYmdHisParts.length != 2) {
    return '';
  }

  return strDtYmdHisParts[0];
};
