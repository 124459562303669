import { HttpPaginationResult, HttpResult } from 'src/services/http';
import { GetTokenService } from 'src/services/storage.service';
import {
  KdsListMenuOptionDto,
  KdsMenuOptionChoiceDomain,
  KdsMenuOptionDomain,
} from 'src/submodules/sicpama-shared';
import { KdsCreateMenuOptionChoiceDto } from 'src/submodules/sicpama-shared/kds/put/menu-option.dto';

import { KdsListCategoryQueryDto } from '../../submodules/sicpama-shared';
import {
  KdsCreateCategoryDto,
  KdsUpdateCategoryDto,
} from '../../submodules/sicpama-shared/kds/put/category.dto';
import { KdsCreateMenuOptionDto } from '../../submodules/sicpama-shared/kds/put/menu-option.dto';
import { KdsCreateMenuDto, KdsEditMenuDto } from '../../submodules/sicpama-shared/kds/put/menu.dto';

import { CategoryDomain, MenuDomain, MenuOptionDomain } from './menu.interface';

export enum MenuSortBy {
  PRICE_HIGH_TO_LOW = 'price_high_to_low',
  PRICE_LOW_TO_HIGH = 'price_low_to_high',
  NAME_ASCENDING = 'name_asc',
  NAME_DESCENDING = 'name_desc',
}

export type CategoryIdFilter = number | 'all';

export type HttpMenuSearchType = {
  categoryId: CategoryIdFilter;
  sortBy: MenuSortBy;
  name: string;
};

export type HttpMenuList = (
  getToken: GetTokenService,
) => (
  storeId: number,
  pageNumber: number,
  pageSize: number,
  search?: Partial<HttpMenuSearchType>,
) => Promise<HttpResult<HttpPaginationResult<MenuDomain[]>>>;

export type HttpMenuSetAvailability = (
  getToken: GetTokenService,
) => (
  storeId: number,
  partial: Array<{ id: number; isAvailable: boolean }>,
) => Promise<HttpResult<{ partial: Array<{ id: number; isAvailable: boolean }> }>>;

export type HttpMenuDelete = (
  getToken: GetTokenService,
) => (storeId: number, id: number) => Promise<HttpResult<boolean>>;

export type HttpCreateMenu = (
  getToken: GetTokenService,
) => (storeId: number, dto: KdsCreateMenuDto) => Promise<HttpResult<MenuDomain>>;

export type HttpEditMenu = (
  getToken: GetTokenService,
) => (storeId: number, menuId: number, dto: KdsEditMenuDto) => Promise<HttpResult<MenuDomain>>;

export type HttpBulkUpdateMenuPriorities = (
  getToken: GetTokenService,
) => (
  storeId: number,
  categoryId: number | string,
  menuPriorities: { menuId: number; priority: number }[],
) => Promise<HttpResult<MenuDomain>>;

export type HttpMenuOptionCreate = (
  getToken: GetTokenService,
) => (storeId: number, dto: KdsCreateMenuOptionDto) => Promise<HttpResult<MenuOptionDomain>>;

export type HttpEditMenuOption = (
  getToken: GetTokenService,
) => (
  storeId: number,
  optionId: number,
  dto: KdsCreateMenuOptionDto,
) => Promise<HttpResult<MenuOptionDomain>>;

export type HttpMenuCategoryCreate = (
  getToken: GetTokenService,
) => (storeId: number, dto: KdsCreateCategoryDto) => Promise<HttpResult<CategoryDomain>>;

export type HttpMenuCategoryUpdate = (
  getToken: GetTokenService,
) => (categoryId: number, dto: KdsUpdateCategoryDto) => Promise<HttpResult<CategoryDomain>>;

export type HttpMenuCategoryList = (
  getToken: GetTokenService,
) => (
  storeId: number,
  query: KdsListCategoryQueryDto,
) => Promise<HttpResult<HttpPaginationResult<CategoryDomain[]>>>;

export type HttpMenuOptionList = (
  getToken: GetTokenService,
) => (
  storeId: number,
  query: KdsListMenuOptionDto,
) => Promise<HttpResult<HttpPaginationResult<MenuOptionDomain[]>>>;

export type HttpCreateMenuOptionChoice = (
  getToken: GetTokenService,
) => (
  storeId: number,
  optionId: number,
  dto: KdsCreateMenuOptionChoiceDto,
) => Promise<HttpResult<KdsMenuOptionChoiceDomain>>;

export type HttpDeleteMenuOptionChoice = (
  getToken: GetTokenService,
) => (
  storeId: number,
  optionId: number,
  optionChoiceId: number,
) => Promise<HttpResult<{ success: boolean }>>;

export type HttpUpdateMenuOption = (
  getToken: GetTokenService,
) => (storeId: number, id: number, optionEntity: any) => Promise<HttpResult<KdsMenuOptionDomain>>;
