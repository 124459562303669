import { Button, Card, Grid } from '@sicpama/core-components';
import { notifications } from '@sicpama/core-components/lib/notifications';
import _ from 'lodash';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { STORE_ATTRIBUTE_NAMES } from 'src/constants';
import { getToken } from 'src/services/storage.service';
import { httpNotifyOrder } from 'src/States/Order/repository/orders.http';
import { notifyOrder } from 'src/States/Order/useCases/notifyOrder';

import {
  getClearTableModalTexts,
  getCombineOrderItems,
  getObjPrintedTimeOrdersByOrders,
  getServedOrdersByOrders,
  getServingOrdersByOrders,
  getStrTimeHourMinByDateTimeValue,
  ObjectPrintedTimeTableOrders,
} from '../../helpers/functions';
import {
  useConfirmModalState,
  useNotificationOrderState,
  useOrderDetailModalState,
  useOrderState,
  useStoreState,
  useStoreTableState,
} from '../../States';
import {
  KdsBaseMenuOptionResDto,
  KdsStoreTableOrderResult,
  OrderStatus,
} from '../../submodules/sicpama-shared';
import AudioPlayer from '../AudioPlayer/AudioPlayer';

import './OrderList.scss';
import { OrderListCheckBoxPair } from './OrderListCheckBoxPair';

export enum OrderListTab {
  SERVING = 'serving',
  DONE = 'done',
}

const translationPrefix = 'table-status';
const OrderList: React.FC<{ orderListTab: OrderListTab }> = ({ orderListTab }) => {
  const { t } = useTranslation();
  const storeData = useStoreState().storeData;
  const { orderData, completedOrdersData } = useOrderState();
  const { openDisplay } = useOrderDetailModalState();
  const { removeTableOrders } = useNotificationOrderState();

  const isPickUpNotificationRequired =
    storeData.attributes?.find(
      (attribute) => attribute.name === STORE_ATTRIBUTE_NAMES.NOTIFICATION_REQUIRED,
    )?.value === 'true';

  const getOrdersDataByComponentCls = (strCls: OrderListTab): KdsStoreTableOrderResult[] => {
    return strCls === OrderListTab.SERVING
      ? getServingOrdersByOrders(orderData)
      : getServedOrdersByOrders(orderData.concat(completedOrdersData));
  };

  const getRenderObjData = (orders: KdsStoreTableOrderResult[]): ObjectPrintedTimeTableOrders => {
    return getObjPrintedTimeOrdersByOrders(orders, orderListTab);
  };

  const handleClick2OrderCardEvent = (tableId: number): void => {
    openDisplay(tableId);
    removeTableOrders(tableId);
  };

  const { clearStoreTable } = useStoreTableState();
  const { openConfirmModal } = useConfirmModalState();
  const singleClick = storeData.attributes.find(
    (attr) => attr.name === STORE_ATTRIBUTE_NAMES.SINGLE_CLICK,
  );
  const singleClickEnabled = singleClick?.value === 'true';

  const handleClearTableEvent = async (tableId: number): Promise<void> => {
    const { title, message } = getClearTableModalTexts();
    if (singleClickEnabled) {
      await clearStoreTable(storeData.id, tableId);
      notifications.show({
        withCloseButton: true,
        autoClose: 2000,
        message: t(`${translationPrefix}.success-resend-pickup`),
        icon: <img src="/icons/checkBg.svg" />,
        color: 'dark',
      });
    } else {
      openConfirmModal(title, message, async () => {
        await clearStoreTable(storeData.id, tableId);
        notifications.show({
          withCloseButton: true,
          autoClose: 1000,
          message: t(`${translationPrefix}.complete-clear-table`),
          icon: <img src="/icons/checkBg.svg" />,
          color: 'dark',
        });
      });
    }
  };

  const getStrOptionChoice = (_option: KdsBaseMenuOptionResDto): string => {
    const strParts = _option.choices.map((choice) => choice.name);
    return strParts.join(', ');
  };

  const sendNotification = useCallback(
    (orderId: number) => async () => {
      await notifyOrder(getToken, httpNotifyOrder)(orderId);
      notifications.show({
        withCloseButton: true,
        autoClose: 2000,
        message: t(`${translationPrefix}.success-resend-pickup`),
        icon: <img src="/icons/checkBg.svg" />,
        color: 'dark',
      });
    },
    [t],
  );

  const renderedOrdersData = getOrdersDataByComponentCls(orderListTab);

  const numOrderData = renderedOrdersData.length;

  const renderObjData = !_.isEmpty(renderedOrdersData) ? getRenderObjData(renderedOrdersData) : {};
  return (
    <div className={`order-list-container ${orderListTab}`}>
      {!_.isEmpty(renderObjData) ? (
        <Grid
          gutter={12}
          lg={Array(numOrderData).fill(12 / 4)}
          md={Array(numOrderData).fill(12 / 3)}
          sm={Array(numOrderData).fill(12 / 2)}
          xs={Array(numOrderData).fill(12 / 2)}
        >
          {Object.keys(renderObjData).map((printedTimeKey) =>
            Object.keys(renderObjData[printedTimeKey]).map((tableKey) => (
              <div
                key={tableKey}
                onClick={() => {
                  AudioPlayer.instance().stopSound();
                  removeTableOrders(Number(tableKey));
                }}
              >
                <Card className="container shadow-md" key={tableKey}>
                  <section className="flex flex-row w-full bg-blue-800 p-2 text-white justify-betweeen">
                    {renderObjData[printedTimeKey][tableKey][0]['isVirtualTable'] === true ? (
                      <p className="font-bold text-lg">
                        {renderObjData[printedTimeKey][tableKey][0]['phoneNumber']
                          ?.replaceAll('-', '')
                          ?.replaceAll(' ', '')
                          ?.slice(-4)}{' '}
                      </p>
                    ) : (
                      <p className="font-bold text-lg">
                        {t(`${translationPrefix}.table`)}
                        {renderObjData[printedTimeKey][tableKey][0]['tableNumber']}
                      </p>
                    )}

                    <p className="ml-auto">
                      {getStrTimeHourMinByDateTimeValue(Number(printedTimeKey))}
                    </p>
                  </section>
                  <section className="content flex flex-col h-[300px] overflow-y-scroll no-scrollbar p-1">
                    {getCombineOrderItems(renderObjData[printedTimeKey][tableKey]).map(
                      (orderItem) => (
                        <div
                          key={`${tableKey}-${orderItem.mocKey}`}
                          className="flex flex-row border border-gray-200 items-center shadow-md mb-2 p-1 min-h-[70px] h-[70px]"
                        >
                          <p className="h-full w-2/3 flex flex-wrap pr-2 items-center">
                            {orderItem.menuSnapshot.name}
                            {orderItem.menuSnapshot.menuOptions.length > 0 &&
                              ` (${orderItem.menuSnapshot.menuOptions
                                .map((option) => {
                                  const optionChoice = getStrOptionChoice(option);
                                  return optionChoice;
                                })
                                .join(', ')})`}
                          </p>

                          <div className="h-full flex flex-row items-center w-1/3 justify-between">
                            <p className="font-bold">{Number(orderItem.menuQuantity)}</p>
                            {renderObjData[printedTimeKey][tableKey].some(
                              (order: KdsStoreTableOrderResult) =>
                                order.orderStatus === OrderStatus.PRINTED,
                            ) && (
                              <OrderListCheckBoxPair
                                orderId={orderItem.orderId}
                                orderItemId={orderItem.id}
                                tableId={Number(tableKey)}
                              ></OrderListCheckBoxPair>
                            )}
                          </div>
                        </div>
                      ),
                    )}
                  </section>
                </Card>
                {renderObjData[printedTimeKey][tableKey].some(
                  (order: KdsStoreTableOrderResult) => order.orderStatus === OrderStatus.PRINTED,
                ) && (
                  <section className="bottom">
                    <div
                      className="clear-table"
                      onKeyDown={() => {}}
                      onClick={() => handleClearTableEvent(Number(tableKey))}
                    >
                      <Button
                        title={
                          singleClickEnabled
                            ? t(`${translationPrefix}.send-noti`)
                            : t(`${translationPrefix}.clear-the-table`)
                        }
                      />
                    </div>
                    <div
                      className="view-table"
                      onKeyDown={() => {}}
                      onClick={() => {
                        sendNotification(Number(tableKey));
                        handleClick2OrderCardEvent(Number(tableKey));
                        AudioPlayer.instance().stopSound();
                      }}
                    >
                      <Button title={t(`${translationPrefix}.view-table`)} />
                    </div>
                  </section>
                )}
                {renderObjData[printedTimeKey][tableKey].some(
                  (order: KdsStoreTableOrderResult) =>
                    order.orderStatus === OrderStatus.COMPLETED && isPickUpNotificationRequired,
                ) && (
                  <section className="flex flex-row">
                    <div
                      className="flex w-full bg-orange-500"
                      onKeyDown={() => {}}
                      onClick={sendNotification(renderObjData[printedTimeKey][tableKey][0].orderId)}
                    >
                      <Button title={t(`${translationPrefix}.send-noti-again`)} />
                    </div>
                  </section>
                )}
              </div>
            )),
          )}
        </Grid>
      ) : (
        <h1 className="no-order">{t(`${translationPrefix}.no-order`)}</h1>
      )}
    </div>
  );
};
export default OrderList;
