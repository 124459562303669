import { create } from 'zustand';

import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../constants';
import { transactionService } from '../../services/transaction.service';
import {
  KdsStoreRefundBodyDto,
  KdsTransactionDetailDomain,
  KdsTransactionDetailResponseDto,
  KdsTransactionListItemDomain,
} from '../../submodules/sicpama-shared';

class CompleteRefundData {
  totalRefundedAmount!: number;
  currency!: string;
}

export type TransactionHistoryState = {
  transactionsData: KdsTransactionListItemDomain[];
  transactionDetailsData: KdsTransactionDetailResponseDto;
  transactionDetailList: Record<string, KdsTransactionDetailDomain>;
  isOpenRefundModal: boolean;
  isOpenCompleteRefundModal: boolean;
  completeRefundData: CompleteRefundData;
  getTransactionsData: (
    storeId: number,
    fromDateTime: string,
    toDateTime: string,
    filterType: string,
    searchKey: string,
    pageIndex?: number,
    pageSize?: number,
  ) => Promise<KdsTransactionListItemDomain[]>;
  getTransactionDetailData: (storeId: number, paymentId: string) => Promise<void>;
  createRefund: (storeId: number, data: KdsStoreRefundBodyDto) => Promise<void>;
  closeRefundModal: () => void;
  openRefundModal: () => void;
  closeCompleteRefundModal: () => void;
  openCompleteRefundModal: () => void;
};

export const useTransactionHistoryState = create<TransactionHistoryState>((set, get) => ({
  transactionsData: [],
  transactionDetailsData: {} as KdsTransactionDetailResponseDto,
  transactionDetailList: {},
  isOpenRefundModal: false,
  isOpenCompleteRefundModal: false,
  completeRefundData: {} as CompleteRefundData,
  getTransactionsData: async (
    storeId: number,
    fromDateTime: string,
    toDateTime: string,
    filterType: string,
    searchKey: string,
    pageIndex?: number,
    pageSize?: number,
  ): Promise<KdsTransactionListItemDomain[]> => {
    const offset = pageIndex ?? DEFAULT_PAGE_INDEX;
    const limit = pageSize ?? DEFAULT_PAGE_SIZE;

    const transactionsData = await transactionService.getTransactionsData(
      storeId,
      fromDateTime,
      toDateTime,
      filterType,
      searchKey,
      offset,
      limit,
    );

    let newTransactionsData = [...transactionsData.data];

    if (offset > DEFAULT_PAGE_INDEX) {
      newTransactionsData = get().transactionsData.concat(newTransactionsData);
    }

    const seen = new Set();
    const filteredTransactions = newTransactionsData.filter((item) => {
      const duplicate = seen.has(item.id);
      seen.add(item.id);
      return !duplicate;
    });

    set({ transactionsData: filteredTransactions });

    return filteredTransactions;
  },

  getTransactionDetailData: async (storeId: number, paymentId: string): Promise<void> => {
    const transactionDetailsData = await transactionService.getTransactionDetailData(
      storeId,
      paymentId,
    );

    set((state) => ({
      ...state,
      transactionDetailList: {
        ...state.transactionDetailList,
        [paymentId]: transactionDetailsData,
      },
    }));
  },
  createRefund: async (storeId: number, data: KdsStoreRefundBodyDto): Promise<void> => {
    await transactionService.createRefund(storeId, data);

    // Save total amount
    let totalRefundedAmount = 0;
    data.orders.forEach((item) => {
      totalRefundedAmount += Number(item.refundAmount);
    });

    const newCompleteRefundData = {
      ...get().completeRefundData,
      totalRefundedAmount,
      currency: get().transactionDetailsData.currency,
    };

    set({ completeRefundData: newCompleteRefundData });
  },
  closeRefundModal: (): void => {
    set({ isOpenRefundModal: false });
  },
  openRefundModal: async (): Promise<void> => {
    set({ isOpenRefundModal: true });
  },
  closeCompleteRefundModal: (): void => {
    set({ isOpenCompleteRefundModal: false });
  },
  openCompleteRefundModal: async (): Promise<void> => {
    set({ isOpenCompleteRefundModal: true });
  },
}));
