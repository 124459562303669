export const DEFAULT_KRW_PRICE_OPTION = {
  style: 'currency',
  currency: 'KRW',
  useGrouping: true,
};

export const CURRENCY_TO_LOCALE_PRICE = {
  KRW: DEFAULT_KRW_PRICE_OPTION,
  SGD: {
    style: 'currency',
    currency: 'SGD',
    useGrouping: true,
  },
};

export const roundNumberTo2DecimalPlaces = (num: number): number =>
  Math.round((num + Number.EPSILON) * 100) / 100;

const numberLocaleFormat = (
  number: number,
  options: Record<string, unknown> = {},
  locale = 'ko-KR',
): string => {
  if (number !== 0 && !number) {
    return '';
  }

  if (typeof number !== 'number') {
    return '';
  }

  return number.toLocaleString(locale, { ...options });
};

export default numberLocaleFormat;
