import { COUNTRY_LOCALES, CURRENCY_OPTIONS } from '../../../src/constants';
import i18n from '../../../src/i18n';

export const getStrOrdinalNumber = (_number: number): string => {
  if (_number % 10 == 1 && _number % 100 != 11) {
    return `${i18n.t('common.st')}`;
  }

  if (_number % 10 == 2 && _number % 100 != 12) {
    return `${i18n.t('common.nd')}`;
  }

  if (_number % 10 == 3 && _number % 100 != 13) {
    return `${i18n.t('common.rd')}`;
  }

  return `${i18n.t('common.th')}`;
};

export const getStrVisitingNumber = (_number: number): string => {
  const visits = _number + 1;
  return `${visits.toString()}${getStrOrdinalNumber(visits)} ${i18n.t('common.visit')}`;
};

export const getStrTimeAgo = (_hour: number, _min: number): string => {
  const arrParts = [];

  if (_hour > 0) {
    arrParts.push(`${i18n.t('common.num-hour').replace('{0}', _hour.toString())}`);
  }

  if (_min > 0) {
    arrParts.push(`${i18n.t('common.num-min').replace('{0}', _min.toString())}`);
  }

  if (arrParts.length === 0) {
    return `${i18n.t('common.just-now')}`;
  }

  return arrParts.join(' ');
};

export const getKickOutDinerModalTexts = (): {
  title: string;
  message: string;
} => {
  return {
    title: i18n.t('kick-out-modal.title'),
    message: i18n.t('kick-out-modal.message'),
  };
};

export const getDinerPaidCashModalTexts = (): {
  title: string;
  message: string;
} => {
  return {
    title: i18n.t('paid-cash-modal.title'),
    message: i18n.t('paid-cash-modal.message'),
  };
};

export const getClearTableModalTexts = (): {
  title: string;
  message: string;
} => {
  return {
    title: i18n.t('kick-out-modal.confirm'),
    message: i18n.t('kick-out-modal.confirm_message'),
  };
};

export const getStrTimeHourMinByDateTimeValue = (_value: number): string => {
  const dt = new Date(_value);
  const hour = dt.getHours();
  const min = dt.getMinutes();
  const strHour = hour > 9 ? hour.toString() : `0${hour}`;
  const strMin = min > 9 ? min.toString() : `0${min}`;

  if (!hour || !min) {
    return '';
  }

  return `${strHour}:${strMin}`;
};

export const currencyFormat = (_number: number, _countryCode: string): string => {
  return Number(_number).toLocaleString(
    COUNTRY_LOCALES[_countryCode],
    CURRENCY_OPTIONS[_countryCode],
  );
};

export const maskName = (text?: string): string => {
  if (!text) return '';

  const englishPattern = /[a-zA-Z]/;

  let visibleLength: number;
  let maskedLength: number;

  const firstChar = text.charAt(0);
  if (englishPattern.test(firstChar)) {
    visibleLength = 3;
    maskedLength = 3;
  } else {
    visibleLength = 1;
    maskedLength = 2;
  }

  const visibleText = text.substring(0, visibleLength);
  const maskedText = '*'.repeat(maskedLength);

  return visibleText + maskedText;
};

export const getShortDayNameOfWeek = (_date: Date) => {
  const shortDayNames = [
    i18n.t('common.sun'),
    i18n.t('common.mon'),
    i18n.t('common.tue'),
    i18n.t('common.wed'),
    i18n.t('common.thu'),
    i18n.t('common.fri'),
    i18n.t('common.sat'),
  ];

  return shortDayNames[_date.getDay()];
};

export const formatDateYmd = (_date: Date) => {
  const year = _date.getFullYear();
  const month = _date.getMonth() + 1;
  const day = _date.getDate();
  const strMonth = month > 9 ? month.toString() : `0${month}`;
  const strDay = day > 9 ? day.toString() : `0${day}`;

  return [year, strMonth, strDay].join('-');
};

export const parseUnixTimestampToStrDtYmdHis = (timestamp: number): string => {
  return new Date(timestamp).toISOString().slice(0, 19).replace('T', ' ');
};

export const maskingString = (str: string, start: number, end: number): string => {
  if (!str || start < 0 || start >= str.length || end < 0 || end > str.length || start >= end) {
    return str;
  }

  const maskLength = end - start;
  const maskedStr = str.substring(0, start) + '*'.repeat(maskLength) + str.substring(end);
  return maskedStr;
};
