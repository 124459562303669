import { TABLE_STATUS } from 'src/types';

import {
  KdsStoreTableOrderResult,
  OrderItemStatus,
  OrderStatus,
  PaymentStatus,
} from '../../submodules/sicpama-shared';

import { getStrTimeAgo } from './Common';
import { getOrderAmount, getOrderPrintedTime, sortOrdersAscByUpdatedAt } from './Order';

export const getStrStartOrderTime = (_orders: KdsStoreTableOrderResult[]): string => {
  const orders = sortOrdersAscByUpdatedAt(_orders);
  const orderTime = new Date(getOrderPrintedTime(orders[0]));
  const hour = orderTime.getHours();
  const min = orderTime.getMinutes();

  const strHour = hour > 10 ? hour.toString() : `0${hour}`;
  const strMin = min > 10 ? min.toString() : `0${min}`;

  return `${strHour}:${strMin}`;
};

export const getStrStartOrderTimeAgo = (_order: KdsStoreTableOrderResult): string => {
  const paymentTime = getOrderPrintedTime(_order);
  if (!paymentTime) {
    return '';
  }

  const now = new Date();
  const dPaymentTime = new Date(paymentTime);
  const duration = now.getTime() - dPaymentTime.getTime();

  const hour = Math.floor(duration / 3600000);
  const min = Math.floor((duration - hour * 3600000) / 60000);

  return getStrTimeAgo(hour, min);
};

export const getStrStartSittingTimeAgo = (_orders: KdsStoreTableOrderResult[]): string => {
  if (_orders.length === 0) {
    return '';
  }

  const orders = sortOrdersAscByUpdatedAt(_orders);
  const now = new Date();
  const dPaymentTime = new Date(orders[0].updatedAt);
  const duration = now.getTime() - dPaymentTime.getTime();

  const hour = Math.floor(duration / 3600000);
  const min = Math.floor((duration - hour * 3600000) / 60000);

  return getStrTimeAgo(hour, min);
};

export const getTableTotalAmount = (_orders: KdsStoreTableOrderResult[]): number => {
  let result = 0;

  _orders.forEach((order) => {
    result += getOrderAmount(order);
  });

  return result;
};

export const getObjectIdTableByTables = (
  _tables: KdsStoreTableOrderResult[],
): { [id: number]: KdsStoreTableOrderResult } => {
  const result = {};

  _tables.forEach((table) => {
    result[table.tableId] = table;
  });

  return result;
};

export const getTableStatusByOrders = (_orders: KdsStoreTableOrderResult[]): string => {
  // Empty table (Grey)
  if (_orders.length === 0) {
    return TABLE_STATUS.EMPTY;
  }

  // Sitting status (Orange)
  if (!_orders.some((order) => order.orderStatus === OrderStatus.PRINTED)) {
    return TABLE_STATUS.SITTING;
  }

  // Cash status (Red)
  if (
    _orders.some(
      (order) =>
        order.paymentStatus === PaymentStatus.PENDING &&
        order.orderStatus === OrderStatus.PRINTED &&
        order.items.some((item) => item.status !== OrderItemStatus.SERVED),
    )
  ) {
    return TABLE_STATUS.CASH;
  }
  if (
    // Serving status (Green)
    _orders.some(
      (order) =>
        order.orderStatus === OrderStatus.PRINTED &&
        order.items.some((orderItem) => orderItem.status !== OrderItemStatus.SERVED),
    )
  ) {
    return TABLE_STATUS.SERVING;
  }
  return TABLE_STATUS.SERVED;
};

export const getOrdersByTableIdAndOrders = (
  tableId: number,
  orders: KdsStoreTableOrderResult[],
): KdsStoreTableOrderResult[] => {
  return orders
    .filter((order) => order.tableId === tableId)
    .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
};

export const getDinerCountByTableIdAndOrders = (
  tableId: number,
  orders: KdsStoreTableOrderResult[],
): number => {
  return orders
    .filter((order) => order.tableId === tableId)
    .filter(
      (o) =>
        new Date(o.createdAt).getTime() >=
        orders
          .filter((c) => c.tableId === o.tableId && c.customerId === o.customerId)
          .map((t) => new Date(t.createdAt).getTime())
          .sort((a: number, b: number) => b - a)[0],
    ).length;
};
