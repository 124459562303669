import { AxiosRequestConfig, AxiosResponse } from 'axios';

import { GetTokenService } from './storage.service';

export type HttpSuccess<T> = {
  success: true;
  data: T;
};

export type HttpError<T = string> = {
  success: false;
  error: T;
};

export type HttpResult<T, E = string> = HttpSuccess<T> | HttpError<E>;

// Type guard for Success
export function isHttpSuccess<T>(result: HttpResult<T>): result is HttpSuccess<T> {
  return result && result.success;
}

export type HttpPaginationResult<T> = { total: number; data: T };

export const getDefaultAxiosConfig = (getToken: GetTokenService): AxiosRequestConfig => ({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: `Bearer ${getToken()}`,
    'store-fingerprint':
      JSON.parse(localStorage.getItem('store-storage') || '{}')?.state?.fingerPrint || '',
  },
  validateStatus: (status) => status >= 200 && status < 501,
});

export const handleHttpResult = <T>(result: AxiosResponse<T, unknown>): HttpResult<T> => {
  if (result.status < 200 || result.status >= 300) {
    return {
      success: false,
      error: `HTTP Error: ${result.status}, ${result.statusText}`,
    };
  }
  return {
    success: true,
    data: result.data,
  };
};
