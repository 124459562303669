import { create } from 'zustand';

import { HttpMenuSearchType, MenuSortBy } from './menu.http.interface';
import {
  CategoryEntities,
  MenuEntities,
  MenuOptionChoiceDomain,
  MenuOptionChoiceEntities,
  MenuOptionDomain,
  MenuOptionEntities,
} from './menu.interface';
import {
  normalizedMenuOptionsChoices,
  NormalizedMenus,
  normalizeMenuOptions,
} from './menu.normalizr';

export interface MenuDataState {
  menus: MenuEntities;
  search: HttpMenuSearchType;
  pageSize: number;
  currentPage: number;
  total: number;
  menuAllIds: number[];
  menuOptionAllIds: number[];
  categories: CategoryEntities;
  menuOptions: MenuOptionEntities;
  menuOptionChoices: MenuOptionChoiceEntities;
}

export interface MenuState extends MenuDataState {
  clearState: () => void;
  resetList: () => void;
  setList: (ids: number[]) => void;
  setTotal: (total: number) => void;
  setBaseMenus: (menus: NormalizedMenus) => void;
  setMenus: (menus: NormalizedMenus) => void;
  setMenuEntities: (menus: MenuEntities) => void;
  setCurrentPage: (currentPage: number) => void;
  setSearch: (search: HttpMenuSearchType) => void;
  setCategories: (categories: CategoryEntities) => void;
  removeMenus: (id: number[]) => void;
  setMenuOption: (menuOptions: MenuOptionDomain[]) => void;
  setMenuOptionChoices: (menuOptionChoices: MenuOptionChoiceDomain[]) => void;
  deleteMenuOptionChoice: (id: number, choiceId: number) => void;
  linkMenuOptionChoice: (optionId: number, optionChoice: MenuOptionChoiceDomain) => void;
}

const initialState: MenuDataState = {
  menus: {},
  menuAllIds: [],
  menuOptionAllIds: [],
  total: 0,
  search: {
    categoryId: 'all',
    sortBy: MenuSortBy.NAME_ASCENDING,
    name: '',
  },
  pageSize: 300,
  currentPage: 1,
  categories: {},
  menuOptions: {},
  menuOptionChoices: {},
};

export const useMenuState = create<MenuState>((set, get) => ({
  ...initialState,
  clearState: () => set(initialState),
  resetList: () => {
    set((state) => ({
      ...state,
      menuAllIds: [],
    }));
  },
  setList: (ids: number[]) => {
    set((state) => ({
      ...state,
      menuAllIds: ids,
    }));
  },
  setTotal: (total: number) => {
    set((state) => ({
      ...state,
      total,
    }));
  },
  setSearch: (search: HttpMenuSearchType) => {
    set((state) => ({
      ...state,
      search,
    }));
  },
  setCurrentPage: (currentPage: number) => {
    set((state) => ({
      ...state,
      currentPage,
    }));
  },
  setCategories: (categories: CategoryEntities) => {
    set((state) => ({
      ...state,
      categories: {
        ...state.categories,
        ...categories,
      },
    }));
  },
  setBaseMenus: (normalizedMenus: NormalizedMenus) => {
    set((state) => ({
      ...state,
      menus: {
        ...state.menus,
        ...normalizedMenus.entities.menus,
      },
      categories: {
        ...state.categories,
        ...normalizedMenus.entities.categories,
      },
      menuOptions: {
        ...state.menuOptions,
        ...normalizedMenus.entities.menuOptions,
      },
      menuOptionChoices: {
        ...state.menuOptionChoices,
        ...normalizedMenus.entities.menuOptionChoices,
      },
    }));
  },
  setMenus: (menus: NormalizedMenus) => {
    const filteredIds = get()
      .menuAllIds.concat(menus.result)
      .filter((v, i, a) => a.indexOf(v) === i);
    get().setBaseMenus(menus);
    set((state) => ({
      ...state,
      menuAllIds: filteredIds,
    }));
  },
  setMenuEntities: (menus: MenuEntities) => {
    set((state) => ({
      ...state,
      menus: {
        ...state.menus,
        ...menus,
      },
    }));
  },
  removeMenus: (ids: number[]) => {
    const toUpdateMenuState = { ...get().menus };
    ids.forEach((id) => delete toUpdateMenuState[id]);
    set((state) => ({
      ...state,
      menus: toUpdateMenuState,
      menuAllIds: state.menuAllIds.filter((menuId) => !ids.includes(menuId)),
    }));
  },
  setMenuOption: (menuOptions: MenuOptionDomain[]) => {
    const normalizedMenuOption = normalizeMenuOptions(menuOptions);
    set((state) => ({
      ...state,
      menuOptions: {
        ...state.menuOptions,
        ...normalizedMenuOption.entities.menuOptions,
      },
      menuOptionChoices: {
        ...state.menuOptionChoices,
        ...normalizedMenuOption.entities.menuOptionChoices,
      },
    }));
  },
  setMenuOptionChoices: (menuOptionsChoices: MenuOptionChoiceDomain[]) => {
    const normalizedMenuOptionChoices = normalizedMenuOptionsChoices(menuOptionsChoices);
    set((state) => ({
      menuOptionChoices: {
        ...state.menuOptionChoices,
        ...normalizedMenuOptionChoices,
      },
    }));
  },
  deleteMenuOptionChoice: (optionId: number, id: number) => {
    const previousRecord = get().menuOptionChoices;
    const menuOption = get().menuOptions[optionId];
    menuOption.menuOptionChoices = menuOption.menuOptionChoices.filter(
      (choiceId) => choiceId !== id,
    );
    delete previousRecord[id];
    set((state) => ({
      menuOptionChoices: previousRecord,
      menuOptions: {
        ...state.menuOptions,
        [menuOption.id]: menuOption,
      },
    }));
  },
  linkMenuOptionChoice: (optionId, optionChoice) => {
    const menuOption = get().menuOptions[optionId];
    menuOption.menuOptionChoices.push(optionChoice.id);
    set((state) => ({
      menuOptions: {
        ...state.menuOptions,
        [menuOption.id]: menuOption,
      },
    }));
  },
}));

const clearState = (state: MenuState) => state.clearState;
const getCurrentPage = (state: MenuState) => state.currentPage;
const getPageSize = (state: MenuState) => state.pageSize;
const getSearch = (state: MenuState) => state.search;
const getIsListDone = (state: MenuState) => Object.keys(state.menus).length === state.total;

const getMenuIds = (state: MenuState) => state.menuAllIds;
const getMenuById = (id: number) => (state: MenuState) => state.menus[id];
const getMenus = (state: MenuState) => Object.keys(state.menus).map((id) => state.menus[id]);

const getCategoryIds = (state: MenuState) => Object.keys(state.categories).map(Number);
const getCategoryById = (id: number) => (state: MenuState) => state.categories[id];
const getCategories = (state: MenuState) =>
  Object.keys(state.categories).map((id) => state.categories[id]);

const getMenuOptionIds = (state: MenuState) => Object.keys(state.menuOptions).map(Number);
const getMenuOptionIdsByMenuId = (id: number) => (state: MenuState) => state.menus[id].menuOptions;
const getMenuOptionById = (id: number) => (state: MenuState) => state.menuOptions[id];

const getMenuOptionChoiceById = (id: number) => (state: MenuState) => state.menuOptionChoices[id];
const getMenuOptionChoiceByIds = (ids: number[]) => (state: MenuState) =>
  ids.map((id) => state.menuOptionChoices[id]);
export const menuSelector = {
  getMenuIds,
  getMenuById,
  getCategoryIds,
  getCategoryById,
  getMenuOptionIds,
  getMenuOptionIdsByMenuId,
  getMenuOptionById,
  getMenuOptionChoiceById,
  getMenuOptionChoiceByIds,
  clearState,
  getPageSize,
  getCurrentPage,
  getSearch,
  getIsListDone,
  getMenus,
  getCategories,
};
