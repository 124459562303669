import { DoubleRightOutlined, RightOutlined } from '@ant-design/icons';
import { Card } from '@sicpama/core-components';
import { notifications } from '@sicpama/core-components/lib/notifications';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuNameComponent } from 'src/utils/menu';

import { orderService, storeTableService } from '../../services';
import { useStoreState } from '../../States';
import { KdsStoreTableOrderResult, OrderItemStatus } from '../../submodules/sicpama-shared';
import numberLocaleFormat, { CURRENCY_TO_LOCALE_PRICE } from '../../utils/numberLocaleFormat';

import CancelPayment from './CancelPayment';

const CompletedOrderCard = ({
  item,
  loadOrders,
  startAnimation,
}: {
  item: KdsStoreTableOrderResult;
  loadOrders: (loadMore?: boolean) => Promise<void>;
  startAnimation: (tabId?: string) => void;
}) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const { id: storeId } = useStoreState((state) => state.storeData);
  const storeData = useStoreState().storeData;

  const isRefunded = useMemo(() => item?.refunds?.length > 0, [item]);

  const onSendReceipt = async () => {
    if (isRefunded) {
      return;
    }
    await storeTableService.sendOrderReceipt(storeId, item.orderId);
    notifications.show({
      withCloseButton: true,
      autoClose: 1000,
      message: t('search-orders.notificationSent'),
      color: 'green',
    });
  };

  const cancelCompletion = async () => {
    if (isRefunded) {
      return;
    }
    await orderService.bulkUpdateOrderItems(
      storeId,
      item.tableId,
      item.items.map((i) => i.id),
      {
        orderItemStatus: OrderItemStatus.DRAFT,
      },
      true,
    );
    loadOrders();
    startAnimation('in-progress-tab');
  };

  const totalQuantity = item.items.reduce(
    (acc, cur) => acc + parseInt(String(cur.menuQuantity)),
    0,
  );

  return (
    <Card key={item.orderId} className="shadow-md p-0 !border-2 border-[#9E9E9E] rounded-[8px]">
      <div className="bg-white h-full flex">
        <div
          className="rounded-md bg-[#8E8E8E] px-1 py-2 flex flex-col items-center justify-between gap-1 shrink-0 w-[58px]"
          onClick={() => setIsExpanded((prev) => !prev)}
        >
          <p className="text-[18px] text-white">{item.orderSequentialNumber}</p>
          <div>
            <p className="text-[12px] text-center text-white">{t('food-truck.order-time')}</p>
            <p className="text-[12px] text-center text-white">
              {dayjs(item.printedAt ?? item.createdAt).format('HH:mm:ss')}
            </p>
          </div>
          <div className="w-full h-auto flex items-center justify-center">
            {item.items.length > 3 ? (
              <DoubleRightOutlined
                className={`text-white ${isExpanded ? '-rotate-90' : 'rotate-90'}`}
              />
            ) : (
              <RightOutlined className={`text-white ${isExpanded ? '-rotate-90' : 'rotate-90'}`} />
            )}
          </div>
        </div>
        <div className="flex flex-col w-full px-2 h-full">
          <div
            className="flex items-center w-full justify-between"
            onClick={() => setIsExpanded((prev) => !prev)}
          >
            <p className="text-[26px] font-bold text-[#4746AD]">
              {item.phoneNumber?.slice(-4) ?? '####'}
            </p>
            <p className="text-[26px] font-bold text-[#5B5B5B]">{totalQuantity}</p>
          </div>
          <div onClick={() => setIsExpanded((prev) => !prev)} className="mb-1">
            {(isExpanded ? item.items : item.items.slice(0, 3)).map((orderItem) => (
              <div key={orderItem.id} className="flex items-start justify-between gap-4">
                <MenuNameComponent menuSnapshot={orderItem.menuSnapshot} />
                <p className="text-[21px] text-[#5B5B5B] font-bold shrink-0 min-w-10">
                  {parseInt(String(orderItem.menuQuantity ?? '0'))}
                </p>
              </div>
            ))}
          </div>

          {isExpanded ? (
            <div className="flex items-end gap-1 mt-auto -mx-2">
              <p className="pl-2 font-bold text-[20px] text-[#212121">
                {t('food-truck.grand-total')}
              </p>
              <p className="font-bold text-[22px] text-[#393894] ml-auto">
                {numberLocaleFormat(
                  parseInt(String(item.grandTotal)),
                  CURRENCY_TO_LOCALE_PRICE[storeData.currency || 'KRW'],
                )}
              </p>
              {!isRefunded && (
                <CancelPayment order={item} isCompletedOrder loadOrders={loadOrders} />
              )}
            </div>
          ) : (
            <div className="flex h-[42px] -mx-2 mt-auto">
              <div
                id="change-order-state-button"
                className="w-2/5 shrink-0 text-white bg-[#4746AD] flex items-center justify-center text-[16px]"
                onClick={cancelCompletion}
              >
                {isRefunded ? t('common.canceled') : t('food-truck.move-to-in-progress')}
              </div>
              <div
                className={`w-3/5 shrink-0 text-[#1E1E1E] ${
                  isRefunded ? 'bg-[#BEBEBE] line-through' : 'bg-[#ADA747]'
                } flex items-center justify-center text-[16px]`}
                onClick={onSendReceipt}
              >
                {t('food-truck.send-receipt')}
              </div>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default CompletedOrderCard;
