export const NAVIGATOR_LANGUAGE_TO_APP_LANGUAGE = {
  ko: 'kr',
  'ko-KR': 'kr',
  en: 'en',
  'en-US': 'en',
  'en-GB': 'en',
  'vi-VN': 'en',
  zh: 'zh',
  'zh-CN': 'zh',
  'zh-TW': 'zh',
  'zh-HK': 'zh',
};
