import { Notifications } from '@sicpama/core-components/lib/notifications';
import { PostHogProvider } from 'posthog-js/react';
import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import { ConfigProvider } from './contexts';
import reportWebVitals from './reportWebVitals';

const root = createRoot(document.getElementById('root')!);

root.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={{
        api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST ?? 'https://us.posthog.com',
      }}
    >
      <ConfigProvider>
        <Notifications position="top-center" color="dark" containerWidth={512} limit={1} />
        <App />
      </ConfigProvider>
    </PostHogProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
